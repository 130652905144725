import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-why-bullymake-a',
  templateUrl: './why-bullymake-a.component.html',
  styleUrls: ['./why-bullymake-a.component.css']
})
export class WhyBullymakeAComponent implements OnInit {

  constructor(private meta:Meta) {

	this.meta.updateTag(
		{
			name: "description",
			content: "Discover how BULLYMAKE revolutionized dog toys for aggressive chewers with durable designs, safe materials, and tailored subscription boxes."
		}
	)
   }

  ngOnInit() {
  }

}
