import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd,  ActivationEnd } from '@angular/router';
import { NotificationService, DataStoreService } from './core/services';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EuDialogComponent } from './layout/dialogs/eu-dialog/eu-dialog.component';
import { ExitIntentComponent } from './layout/dialogs/exit-intent/exit-intent.component';
import {  map } from 'rxjs/operators';
import exitIntent from 'exit-intent';
import { Subscription } from 'rxjs';
import { TrackingService } from './modules-page/my-account/tracking/tracking.service';
import { AppService } from './app.service';
import { API_Response, Customer, Initialize, Notification } from 'bullymake-types';
import { AuthService } from './core/services/auth/auth.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {

	/**
	 * Customer Object from DataStore Service
	 */
	public customer: Customer | null = null;


	public snaptrInit: boolean = false;

	/**
	 * Component Subscriptions Array
	 */
	public subscriptions: Subscription[] = [];

	constructor(
		private service: AppService,
		private authService: AuthService,
		private dataStore: DataStoreService,
		private trackingService: TrackingService,
		public snackBar: MatSnackBar,
		public notificationService: NotificationService,
		private router: Router,
		private dialog: MatDialog,
		) { }

	onActivate(event) {

		window.scroll(0, 0);
	}

	ngOnInit() {

		// Get Query Params
		const queryParams: URLSearchParams = new URLSearchParams(window.location.search);

		// Check for Tagger Transaction ID
		const taggerTransactionId: string | null = queryParams.get('transaction_ID');

		// If Tagger Transaction ID Exists, Record Tagger Session
		if( taggerTransactionId )  {

			// Subscribe to Tagger Session
			const tagger$: Subscription = this.service.recordTaggerSession(taggerTransactionId)
				.subscribe((response: API_Response<null>) => {

				});

			// Push Subscription
			this.subscriptions.push(tagger$);
		}

		/**
		 * Subscribe to Customer
		 */
		const customer$: Subscription = this.dataStore.customer.subscribe((customer: Customer | null): void => {

			// Set Customer
			this.customer = customer;

			// If Customer is Null, Return
			if( !customer ) return;

			// @ts-ignore
			_learnq.push(['identify', {
				'$email': customer.email,
				'$first_name': customer.first_name,
				'$last_name': customer.last_name,
			}]);
		});

		// Push Subscription
		this.subscriptions.push(customer$);

		this.initialize();

		this.gdprCheck();

		this.notificationService.notificationState
			.subscribe((data: Notification) => { this.openSnackbar(data.message); });

		this.router.events
			.subscribe((val) => {

				if (val instanceof NavigationEnd) {

					// @ts-ignore
					window.edgetag('tag', 'PageView');

					this.trackingService.gtm('PageView');
					this.trackingService.gtm('ViewContent');
					// @ts-ignore
					_mfq.push(["newPageView", this.router.url]);

					if (this.customer && !this.snaptrInit) {
						// @ts-ignore
						snaptr('init', 'd5392891-6572-4ec3-9551-be89bfcc07ff', {
							'user_email': this.customer.email
						});
					}

					// @ts-ignore
					snaptr('track', 'PAGE_VIEW');

					// TikTok
					// @ts-ignore
					if (typeof ttq != "undefined") ttq.track('ViewContent');

					// @ts-ignore
					if (typeof ttq != "undefined") letterpress('trackPageView');

				}

				if (val instanceof ActivationEnd) {
					this.activePathClassName = 'active-' + val.snapshot.routeConfig.path.replace(/\//g, '-');
				}

			});

		// this.initExitIntent();
	}

	public activePathClassName: string = '';

	/**
	 * Lifecycle Hook: OnDestroy
	 */
	ngOnDestroy(): void {

		// Unsubscribe from all subscriptions
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	/**
	 *
	 */
	initialize() {

		// Initialize
		this.service.initialize()
			.pipe(
				map((response: API_Response<Initialize>): Initialize => {

					// Return Response
					return response.data;
				})
			)
			.subscribe((data: Initialize) => {

				// TODO: Document this
				localStorage.setItem('uip', data.uip);

				// TODO: Document this
				localStorage.setItem('ua', data.ua);

				// Update Customer Object
				this.dataStore.customer$.next(data.customer);

				// Make sure we always remove expired token from local storage
				if( !data.customer ) this.authService.logout();
			});
	}

	openSnackbar(message: string) {
		this.snackBar.open(message, 'Close', {
			duration: 3500,
		});
	}


	gdprCheck() {

		let now = Math.floor(Date.now() / 1000);

		if (localStorage.getItem('eu_usercheck') && (now <= parseInt(localStorage.getItem('eu_usercheck')))) return;

		let expiry = now + (60 * 60 * 24 * 7 * 4);

		this.service.euCheck()
			.subscribe(data => {

				if (!data.is_eu) {
					localStorage.setItem('eu_usercheck', String(expiry));
					return;
				}

				// Open Modal
				const dialogConfig = new MatDialogConfig();
				let dialogRef = this.dialog.open(EuDialogComponent, dialogConfig);

				dialogRef.afterClosed().subscribe(value => {

					if (value == 'yes')
						localStorage.setItem('eu_usercheck', String(expiry));

					if (value == 'no')
						window.location.href = 'http://www.google.com';
				});
			});
	}

	initExitIntent() {
		setTimeout(() => {
			exitIntent({
				onExitIntent: () => {
					if (localStorage.getItem('exit_intent_fired') !== '1') {
						localStorage.setItem('exit_intent_fired', '1');
						this.dialog.open(ExitIntentComponent, new MatDialogConfig());
					}
				}
			});
		}, 5000);
	}
}
