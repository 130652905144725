import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-dog-gift',
  templateUrl: './dog-gift.component.html',
  styleUrls: ['./dog-gift.component.css']
})
export class DogGiftComponent implements OnInit {

  constructor(private meta:Meta) {

	this.meta.updateTag(
		{
			name: "description",
			content: "Give the perfect gift with BULLYMAKE Dog Boxes. Durable toys and tasty treats designed for power chewers make every dog’s day special!"
		}
	)
  }

  ngOnInit() {
  }

}
