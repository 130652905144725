<section class="hero-section-b visible-on-b">
	<div class="content-primary position-relative py-5">
		<div class="container">
			<div class="container-content text-center text-uppercase">
				<h1 class="text-dark" style="line-height: 1">
					Durable Dog <span class="d-block text-primary">toys and delicious treats</span>
				</h1>

				<h2 style="line-height: 1">For <span class="bg-primary text-white" style="border-radius: 8px; padding: 4px 8px 0px 8px;">Power</span> Chewers</h2>

				<a routerLink="/subscribe" class="section-cta" aria-label="Get Your $19 First Box">Get Your $19 First Box</a>
			</div><!-- END .container-content -->
		</div><!-- END .container -->
	</div><!-- END .content-primary -->

	<div class="content-secondary py-5">
		<div class="container">
			<div class="container-content border overflow-hidden" style="border-radius: 15px; border-color: #FF6C17 !important;">
				<div class="row">
					<div class="col-lg-4 align-content-center bg-primary">
						<div class="column-content bg-primary px-5 py-4">
							<h2 class="mb-0 text-white text-center text-lg-left text-uppercase" style="line-height: 1">Whats inside our boxes</h2>
						</div>
					</div><!-- END .col-lg-4 -->

					<div class="col-lg-8">
						<div class="row text-center py-5 px-4">
							<div class="col-lg-4 mb-5 mb-lg-0">
								<div class="column-content">
									<img src="https://assets.cdn.bullymake.com/uploads/2025/01/treats2.svg" alt="">
									<strong class="d-block h4">2-3 Guaranteed</strong>
									<span class="px-5 px-lg-0 d-lg-block bg-primary text-white text-uppercase">Tough Toys</span>
								</div><!-- END .column-content -->
							</div><!-- END .col-lg-4 -->

							<div class="col-lg-4 mb-5 mb-lg-0">
								<div class="column-content">
									<img src="https://assets.cdn.bullymake.com/uploads/2025/01/guaranteed2.svg" alt="">
									<strong class="d-block h4">3 Healthy Treats</strong>
									<span class="px-5 px-lg-0 d-lg-block bg-primary text-white text-uppercase">Made in the USA</span>
								</div><!-- END .column-content -->
							</div><!-- END .col-lg-4 -->

							<div class="col-lg-4">
								<div class="column-content">
									<img src="https://assets.cdn.bullymake.com/uploads/2025/01/fun.svg" alt="">
									<strong class="d-block h4">Fun Themes</strong>
									<span class="px-5 px-lg-0 d-lg-block bg-primary text-white text-uppercase">New Every Month</span>
								</div><!-- END .column-content -->
							</div><!-- END .col-lg-4 -->
						</div><!-- END .row -->
					</div><!-- END .col-sm-8 -->
				</div><!-- END .row -->
			</div><!-- END .container-content -->
		</div><!-- END .container -->
	</div><!-- END .content-secondary -->
</section>

<section class="section-item light-section-1 visible-on-a">
	<div class="d-sm-none d-block w-100">
		<img src="https://assets.cdn.bullymake.com/uploads/2025/03/home-hero-march-2025-mobile-67c2a476bcc25.webp"
			class="img-fluid w-100" style="margin-top: -30px; margin-bottom: -40px" />
	</div>
	<div class="container">
		<div class="row pb-5">
			<div class="col-lg-7 col-12 d-none d-sm-block">
				<div class="column-content">
					<img src="https://assets.cdn.bullymake.com/uploads/2025/03/home-hero-march-2025-desktop-67c2a476ae54f.webp"
						class="img-fluid d-md-block d-none" />
					<img src="https://assets.cdn.bullymake.com/uploads/2025/03/home-hero-march-2025-tablet-67c2a4789bb1b.webp"
						class="img-fluid d-md-none d-block" style="margin-top: -50px; margin-bottom: -40px" />
				</div>
			</div>
			<div class="col-lg-5 col-12">
				<div
					class="column-content bg-lg-white bb-lg d-flex justify-content-center flex-column align-items-center pb-4 pt-0">
					<h3 class="block-badge text-white text-center mb-md-4 mb-3 d-none d-lg-block"
						style="font-size: 2.3rem">
						Get Your First Box for <strong class="text-primary">$19</strong>
					</h3>

					<h4 class="text-uppercase mt-3">
						<img class="mx-3"
							src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/one-icon.webp"
							alt="spark" style="transform: rotate(90deg)" />
						<span>For
							<strong class="bg-primary text-white px-1 pt-1">Power</strong>
							Chewers</span>
						<img class="mx-3"
							src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/one-icon.webp"
							alt="spark" style="transform: rotate(330deg)" />
					</h4>
					<h1 class="p-0 mb-4">
						Durable Dog Toys<br class="d-lg-block d-inline-block" />
						& Delicious Treats
					</h1>

					<!-- <h5 class="mb-5">
						<span class="text-primary">Customized</span>
						Monthly
						<span class="position-relative mb-5">
							Themed
							<img src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/underline-tough-scribble.svg"
								class="img-fluid position-absolute" style="bottom:-10px;left: 0px;">
						</span> Boxes For <span class="text-primary">Every Size</span>
						Pup!
					</h5> -->
					<div class="mb-0 w-100 px-4">
						<a class="btn btn-green btn-lg d-block" routerLink="/subscribe" href="#plans">
							<span class="d-none d-lg-block">GET OFFER NOW</span>
							<strong class="d-block d-lg-none" style="font-size: 1.5rem;">Get Your $19 First Box</strong>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="position-relative">
	<div class="embed-responsive embed-responsive-16by9">
		<video #video fetchpriority="high" preload="auto" width="100%" height="auto" playsinline loop autoplay muted
			oncanplay="this.muted=true"
			src="https://assets.cdn.bullymake.com/uploads/2024/07/bullymake-v3d-2023-update.webm" class=""
			style="cursor: pointer">
			<source srcset="
					https://assets.cdn.bullymake.com/uploads/2024/07/bullymake-v3d-2023-update.webm
				" type="video/webm" media="(max-width: 767px)" />
			<source srcset="
					https://assets.cdn.bullymake.com/uploads/2024/07/bullymake-v3d-2023-update.webm
				" type="video/webm" media="(min-width: 768px)" />
		</video>
	</div>
	<button *ngIf="!isPlaying" class="btn btn-primary video-control video-control-play" type="button" (click)="play()">
		<img class="img-fluid d-block" src="https://assets.cdn.bullymake.com/media/images/039-play-button.svg" alt="" />
	</button>
	<button *ngIf="isPlaying" class="btn btn-primary video-control video-control-pause" type="button" (click)="pause()">
		<img class="img-fluid d-block" src="https://assets.cdn.bullymake.com/media/images/037-pause-button.svg"
			alt="" />
	</button>
	<button *ngIf="!isMuted" class="btn btn-primary video-control video-control-mute" type="button" (click)="mute()">
		<img class="img-fluid d-block" src="https://assets.cdn.bullymake.com/media/images/035-sound.svg" alt="" />
	</button>
	<button *ngIf="isMuted" class="btn btn-primary video-control video-control-unmute" type="button" (click)="unmute()">
		<img class="img-fluid d-block" src="https://assets.cdn.bullymake.com/media/images/034-mute.svg" alt="" />
	</button>

	<!-- <div *ngIf="!isPlayed" class="initial-poster" (click)="onClickPoster()">

	</div> -->
</section>

<!-- OLD HERO SECTION V1 -->
<!-- <section class="section-item light-section-1 ">
	<div class="container">
		<div class="row pb-5 mt-3 pt-md-5 mt-md-5">
			<div class="col-xl-5 col-lg-6 ">
				<div class="column-content position-relative" >

					<h4 class="text-uppercase">For Power Chewers</h4>
					<h1 class=" p-0">Durable Dog Toys & <br class="d-lg-none d-inline-block"> Delicious Treats</h1>
					<h4 class="mb-5"><span class="text-primary">Customized</span> Monthly Boxes For Every Size
						Pup!
					</h4>

					<div class="d-lg-flex d-none align-items-center mb-5">
						<a class="btn btn-lg btn-green mr-4 mb-2 " routerLink="/subscribe" href="#plans">Get
							Started</a>
						<h5>See if your dog is tough enough!</h5>
					</div>

					<div class="d-lg-none d-flex align-items-center mb-5">
						<a class="btn btn-green mr-4 mb-2 " routerLink="/subscribe" href="#plans">Get
							Started</a>
						<h6>See if your dog is tough enough!</h6>
					</div>

					<div class="d-lg-none d-block">

						<img class="img-fluid position-absolute d-sm-none" width="170px" style="z-index: 2; top:-20%; right:0%;"
							src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/header-dog_desktop_light-background-at-2x.webp"
							alt="Dog Img">

						<img class="img-fluid position-absolute d-sm-block d-md-none d-none" width="220px"
							style="z-index: 2;top:-20%;right:-5%;"
							src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/header-dog_desktop_light-background-at-2x.webp"
							alt="Dog Img">

						<img class="img-fluid position-absolute d-md-block d-none" width="280px"
							style="z-index: 2;top:0%;right:0px;"
							src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/header-dog_desktop_light-background-at-2x.webp"
							alt="Dog Img">

					</div>


					<img class="img-fluid position-absolute d-lg-block d-none" width="450px" style="z-index: 2;"
						src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/header-dog_desktop_light-background-at-2x.webp"
						alt="Dog Img">
				</div>
			</div>
			<div class="col-xl-1 d-none d-xl-block"></div>
			<div class="col-xl-6 col-lg-6">
				<div class="column-content position-relative">
					<div class="card border-0 shadow shadow-lg pt-4  mb-3 ">
						<div class="card-header border-0 bg-transparent text-center text-capitalize">
							<h2 class="d-lg-none d-block">We dare you to break these toys!</h2>
							<h3 class="d-lg-block d-none">We dare you to break these toys!</h3>
							<h4 class="text-primary">What's in a monthly box</h4>
							<div class="px-5 ">
								<hr>
							</div>
						</div>
						<div class="card-body px-4 border-0 py-lg-3 py-3">
							<div class="row">
								<div class="col-6">
									<div class="d-flex flex-column flex-md-row align-items-start mt-md-3 justify-content-center">
										<img class="mr-2 mb-2"
											src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/toys-lockup-sketch.svg"
											alt="Play Illustrations">
										<div>
											<h5 class="text-primary mb-0 pb-0">2 to 3 Tough Toys</h5>
											<small><small><strong>Nylon,Rubber,Ballistic,Rope or
														Plush</strong></small></small>
										</div>
									</div>
								</div>
								<div class="col-6">
									<div class="d-flex flex-column flex-md-row align-items-start justify-content-end">
										<img class="mr-2 mb-2"
											src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/treats-lockup-sketch.svg"
											alt="Treat Illustrations">
										<div>
											<h5 class="text-primary mb-0 pb-0">3 Bags of Treats</h5>
											<small><small><strong>Delicious & Nutritious Treats</strong></small></small>
										</div>
									</div>
								</div>
							</div>
							<img class="img-fluid d-lg-block d-none"
								src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/box-grouping-at-2x.webp" alt="Box Image">
						</div>
						<a routerLink="/subscribe" class="card-footer text-center bg-dark border-0 text-white py-4 text-decoration-none text-uppercase d-none d-md-block" style="cursor: pointer;">
							<span style="font-size: 1.5rem;">Join the toughest club
								<img src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/arrow-cta_desktop.svg"
									alt="arrow icon">
							</span>
						</a>
					</div>
					<div class="d-lg-none d-block">
						<img style="z-index: 2;" class="img-fluid position-absolute d-md-block d-none"
							src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/box-grouping-at-2x.webp" alt="Box Image">

						<img style="z-index: 2;" class="img-fluid d-md-none d-block position-absolute "
							src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/box-grouping.webp" alt="Box Image">
					</div>

				</div>
			</div>
		</div>
	</div>
</section> -->

<!-- Join Now Section -->
<section class="section-item light-section-2">
	<div class="container">
		<div class="row pt-md-5 text-md-left text-center">
			<div class="col-lg-4 mb-5">
				<h2 class="text-uppercase mt-5 mt-md-0">
					<span class="position-relative">
						<img src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/heading-wag-illustration-left.svg"
							class="img-fluid position-absolute" style="top: -28px; left: -28px" />
						Our
					</span>
					tough nylon and rubber dog toys...
				</h2>
				<h4>
					have been
					<span class="text-primary">subscriber tested</span> and
					<span class="text-primary">approved!</span>
				</h4>
				<a class="btn btn-primary btn-lg mr-4" routerLink="/subscribe" href="#plans">Subscribe Now</a>
			</div>
			<div class="col-lg-8 position-relative">
				<div class="slider-prev position-absolute h-100 d-lg-flex d-none align-items-center"
					style="top: 0px; left: -10px; z-index: 1">
					<button class="slider-button shadow rounded" (click)="scroll(sliderContainerCards, -250)">
						<img
							src="data:image/svg+xml,%3Csvg width='22' height='38' viewBox='0 0 22 38' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_0_39547' style='mask-type:luminance' maskUnits='userSpaceOnUse' x='0' y='0' width='22' height='38'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M21.5747 37.575H0.574707L0.574707 0.574951H21.5747L21.5747 37.575Z' fill='white'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_0_39547)'%3E%3Cpath opacity='0.25' fill-rule='evenodd' clip-rule='evenodd' d='M18.8204 0.574951C19.5254 0.574951 20.2297 0.847915 20.7681 1.39315C21.8436 2.48291 21.8436 4.25195 20.7681 5.34171L7.22392 19.0751L20.7681 32.8078C21.8436 33.8983 21.8436 35.6666 20.7681 36.7571C19.692 37.8476 17.9487 37.8476 16.8726 36.7571L1.38129 21.0494C0.305846 19.9589 0.305846 18.1906 1.38129 17.1001L16.8726 1.39315C17.411 0.847915 18.1153 0.574951 18.8204 0.574951' fill='%23382615'/%3E%3C/g%3E%3C/svg%3E%0A" />
					</button>
				</div>

				<div class="slider-content">
					<div class="slides overflow-scroll hide-scrollbar h-100 smooth-scroll" #sliderContainerCards>
						<div class="d-flex pb-4 px-4">
							<!-- <iframe width="100%" height="315" src="https://www.youtube.com/embed/pYeXCT_SLqI?si=HWZPRKklkDphkNMg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> -->

							<div *ngFor="let item of videoCards"
								class="card video-card shadow border-0 mr-3 mr-md-4 p-md-3">
								<!-- <div class="card-img-top position-relative" (click)="viewVideo(item.videoLink)">
									<img class="img-fluid"
										[src]="item.thumbnail">
									<div class="position-absolute w-100 d-flex align-items-center justify-content-center h-100 bg-overlay cursor-pointer"
										style="top:0px;">
										<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="white"
											class="bi bi-play-circle" viewBox="0 0 16 16">
											<path
												d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
											<path
												d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445" />
										</svg>
									</div>
								</div> -->

								<div class="card-img-top position-relative">
									<!-- <iframe width="100%" height="320"  src="https://www.youtube.com/embed/pYeXCT_SLqI?si=HWZPRKklkDphkNMg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> -->
									<iframe width="100%" height="315" [src]="item.videoLink"
										title="YouTube video player" frameborder="0"
										allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
										referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
								</div>

								<div class="card-body">
									<h4 class="text-primary text-uppercase">
										{{ item.title }}
									</h4>
									<h4 class="text-capitalize mb-0">
										{{ item.subTitle }}
									</h4>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="position-absolute h-100 align-items-center justify-content-end slider-gradient-end d-lg-flex d-none"
					style="top: 0px; right: -20px; z-index: 1">
					<button class="slider-button shadow rounded" (click)="scroll(sliderContainerCards, 250)">
						<img
							src="data:image/svg+xml,%3Csvg width='22' height='38' viewBox='0 0 22 38' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_0_40369' style='mask-type:luminance' maskUnits='userSpaceOnUse' x='0' y='0' width='22' height='38'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.643738 0.356201H21.6437V37.3562H0.643738V0.356201Z' fill='white'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_0_40369)'%3E%3Cpath opacity='0.25' fill-rule='evenodd' clip-rule='evenodd' d='M3.39809 37.3562C2.69306 37.3562 1.98873 37.0832 1.45032 36.538C0.374878 35.4482 0.374878 33.6792 1.45032 32.5894L14.9945 18.856L1.45032 5.12331C0.374878 4.03285 0.374878 2.26451 1.45032 1.17405C2.52645 0.0835853 4.26973 0.0835853 5.34586 1.17405L20.8372 16.8817C21.9126 17.9722 21.9126 19.7405 20.8372 20.831L5.34586 36.538C4.80745 37.0832 4.10312 37.3562 3.39809 37.3562' fill='%23382615'/%3E%3C/g%3E%3C/svg%3E%0A" />
					</button>
				</div>
			</div>
			<div class="d-lg-none col-12">
				<div class="d-flex align-items-center justify-content-center pt-3">
					<button class="slider-button rounded mr-5" (click)="scroll(sliderContainerCards, -250)">
						<img
							src="data:image/svg+xml,%3Csvg width='22' height='38' viewBox='0 0 22 38' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_0_39547' style='mask-type:luminance' maskUnits='userSpaceOnUse' x='0' y='0' width='22' height='38'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M21.5747 37.575H0.574707L0.574707 0.574951H21.5747L21.5747 37.575Z' fill='white'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_0_39547)'%3E%3Cpath opacity='0.25' fill-rule='evenodd' clip-rule='evenodd' d='M18.8204 0.574951C19.5254 0.574951 20.2297 0.847915 20.7681 1.39315C21.8436 2.48291 21.8436 4.25195 20.7681 5.34171L7.22392 19.0751L20.7681 32.8078C21.8436 33.8983 21.8436 35.6666 20.7681 36.7571C19.692 37.8476 17.9487 37.8476 16.8726 36.7571L1.38129 21.0494C0.305846 19.9589 0.305846 18.1906 1.38129 17.1001L16.8726 1.39315C17.411 0.847915 18.1153 0.574951 18.8204 0.574951' fill='%23382615'/%3E%3C/g%3E%3C/svg%3E%0A" />
					</button>

					<button class="slider-button rounded" (click)="scroll(sliderContainerCards, 250)">
						<img
							src="data:image/svg+xml,%3Csvg width='22' height='38' viewBox='0 0 22 38' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_0_40369' style='mask-type:luminance' maskUnits='userSpaceOnUse' x='0' y='0' width='22' height='38'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.643738 0.356201H21.6437V37.3562H0.643738V0.356201Z' fill='white'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_0_40369)'%3E%3Cpath opacity='0.25' fill-rule='evenodd' clip-rule='evenodd' d='M3.39809 37.3562C2.69306 37.3562 1.98873 37.0832 1.45032 36.538C0.374878 35.4482 0.374878 33.6792 1.45032 32.5894L14.9945 18.856L1.45032 5.12331C0.374878 4.03285 0.374878 2.26451 1.45032 1.17405C2.52645 0.0835853 4.26973 0.0835853 5.34586 1.17405L20.8372 16.8817C21.9126 17.9722 21.9126 19.7405 20.8372 20.831L5.34586 36.538C4.80745 37.0832 4.10312 37.3562 3.39809 37.3562' fill='%23382615'/%3E%3C/g%3E%3C/svg%3E%0A" />
					</button>
				</div>
			</div>
		</div>
	</div>
</section>

<!-- Toys Section -->
<section class="section-item light-section-4">
	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="d-flex justify-lg-content-end justify-content-center align-items-center mb-5 flex-wrap">
					<div class="d-flex justify-content-center flex-column align-items-center mr-lg-4 mb-5">
						<h2 class="text-uppercase mb-3">
							SUPER
							<span class="position-relative mb-5">
								Tough
								<img src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/underline-tough-scribble.svg"
									class="img-fluid position-absolute" style="bottom: -10px; left: 0px" />
							</span>
							TOYS + TREATS
						</h2>

						<p class="lead mb-4 text-center">
							Customize your box with your choice of any of our
							below toy types. Select the toys that match your
							dog's play style best.<br />
							Our toughest toys, Nylon and Rubber, as well as our treats are made in the USA.
						</p>
						<a class="btn btn-primary btn-lg mr-4" routerLink="/subscribe" href="#plans">Get Chewing</a>
					</div>
					<img src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/badge-tilted.svg"
						alt="Badge titled" class="img-fluid d-block" />
				</div>
			</div>
		</div>

		<div class="row justify-content-center">
			<div class="col-md-6 col-xl-4 mb-5">
				<app-toy-flip [imgUrl]="
						'https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/product-card-updated-nylon-at-2x.webp'
					" [durability]="3" [toyTitle]="'Nylon'" [toySubtitle]="'The Invincible Titan'" [durabilityName]="'Ultra Chewer'">
					<p flipText class="text-light">
						At the top of our toughness scale is the Nylon toy. It's
						like the superhero of dog toys, built for the most
						ferocious chewers on the planet. Think your pup has jaws
						of steel? Challenge accepted. Our Nylon toys are for the
						ultimate chewers, the legends of the dog park, the ones
						who make other toys quiver in fear!
					</p>
				</app-toy-flip>
			</div>
			<div class="col-md-6 col-xl-4 mb-5">
				<app-toy-flip [imgUrl]="
						'https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/product-card-updated-rubber-at-2x.webp'
					" [durability]="3" [toyTitle]="'Rubber'" [toySubtitle]="'The Resilient Warrior'"
					[durabilityName]="'Power Chewer'">
					<p flipText class="text-light">
						Next in line is our Rubber toy. It's like the trusty
						sidekick to our Nylon hero - still incredibly tough, but
						with a bit more 'give.' Perfect for dogs who love a good
						gnaw but aren't quite in the 'nylon' league. These toys
						are bouncy, durable, and ready for some serious
						playtime.
					</p>
				</app-toy-flip>
			</div>
			<div class="col-md-6 col-xl-4 mb-5">
				<app-toy-flip [imgUrl]="
						'https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/product-card-updated-rope-at-2x.webp'
					" [durability]="2" [toyTitle]="'Rope'" [toySubtitle]="'The Tough Tugger'" [durabilityName]="'Active Chewer'"
					[isSampleToy]="true">
					<p flipText class="text-light">
						For the dogs that love a good game of tug-of-war, our
						Rope toys are your go- to. Tough enough to withstand a
						good thrashing but safe for those precious canines.
					</p>
				</app-toy-flip>
			</div>
			<div class="col-md-6 col-xl-4 mb-5">
				<app-toy-flip [imgUrl]="
						'https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/product-card-updated-ballistic-at-2x.webp'
					" [durability]="2" [toyTitle]="'Ballistic'" [toySubtitle]="'The Durable Changer'"
					[durabilityName]="'Active Chewer'">
					<p flipText class="text-light">
						Our Ballistic toys are for the pups who like a bit of
						roughhousing but in a more refined manner. They're
						tough, they're durable, but they've got a gentler side
						too.
					</p>
				</app-toy-flip>
			</div>
			<div class="col-md-6 col-xl-4 mb-5">
				<app-toy-flip [imgUrl]="
						'https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/product-card-updated-plush-at-2x.webp'
					" [durability]="1" [toyTitle]="'Plush'" [toySubtitle]="'The Soft but Sturdy'" [durabilityName]="'Soft Chewer'">
					<p flipText class="text-light">
						And finally, for the dogs who are tough but like to keep
						it cozy, we have our tough Plush toys. They're the
						softest in our lineup, perfect for a dog who enjoys a
						good chew session followed by a cuddle.
					</p>
				</app-toy-flip>
			</div>
			<div class="col-md-6 col-xl-4">
				<app-toy-flip [imgUrl]="
						'https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/product-card-updated-treats-at-2x.webp'
					" [toyTitle]="'Custom Treats'" [toySubtitle]="'Great In Every Bite'">
					<p flipText class="text-light">
						Each box will contain delicious and healthy treats for
						your pup. Meaty and all natural chews that your dog can
						enjoy. Some treats match perfectly with the toys we
						provide. You can stick them inside our rubber toys for
						your dogs to enjoy hours on end. Allergy preferences can
						be selected at any time during the life of your
						subscription!
					</p>
				</app-toy-flip>
			</div>
		</div>
	</div>
</section>

<!-- Why Choose Section -->
<section class="section-item light-section-5 text-center position-relative">
	<h2 class="position-absolute" style="top: 0px; left: 0px; right: 0px">
		<span class="text-light bb bg-primary d-inline-block text-uppercase px-5 py-2">Why Choose
			<img class="img-fluid"
				src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/bullymake-tag.webp"
				alt="bullymake tag" />
			Bullymake</span>
	</h2>

	<div class="container mt-5">
		<div class="row mt-5 justify-content-center">
			<div class="col-lg-4 col-md-6 align-items-stretch mb-md-0 mb-4 pb-md-3 pb-5">
				<img style="max-height: 90px; height: 90px" class="img-fluid mb-5"
					src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/differentiators-extreme-icon.svg"
					alt="For Extreme Chewers" />
				<h4>For Extreme Chewers</h4>
				<p class="fw-light lead">
					We're So Confident, We Guarantee It!
				</p>
				<h5>
					We manufacture and ship the tough stuff. If your dog is an
					extreme chewer, tugger, fetcher, or player -
					<span class="text-primary">we got you covered.</span>
				</h5>
			</div>

			<div class="col-lg-4 col-md-6 align-items-stretch mb-md-0 mb-4 pb-md-3 pb-5">
				<img style="max-height: 90px; height: 90px" class="img-fluid mb-5"
					src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/differentiators-dare-icon.svg"
					alt="We dare to be different" />
				<h4>Dare to Be Different</h4>
				<p class="fw-light lead">No Bones Here - Unique Shapes Only!</p>
				<h5>
					Industry leading durability and satisfaction guarantee.
					Nothing destroyed or leaves your dog unsatisfied, or we
					<span class="text-primary">replace it for free.</span>
				</h5>
			</div>

			<div class="col-lg-4 col-md-6 align-items-stretch">
				<img style="max-height: 90px; height: 90px" class="img-fluid mb-5"
					src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/differentiators-usa-icon.svg"
					alt="Built tough in the U.S.A." />
				<h4>Built Tough in the U.S.A.</h4>
				<p class="fw-light lead">With Globally Sourced Components</p>
				<h5>
					Our specialty Nylon and Rubber toys as well as our tasty treats are <span class="text-primary">made in the USA.</span>
				</h5>
			</div>
		</div>
	</div>
</section>

<section id="section-safety" class="section-item">
	<div class="container">
		<div class="row no-gutters">
			<div class="col-md-6 bg-light">
				<div class="d-flex h-100 flex-column justify-content-between">
					<div class="p-5">
						<h2>
							<span class="position-relative">NOT ONLY TOUGH,
							</span>
							<span class="position-relative">
								BUT SAFE
								<img class="position-absolute" style="left: 0; bottom: 0px" width="100%"
									src="https://assets.cdn.bullymake.com/uploads/2024/07/heading-underline.svg"
									alt="" />
							</span>
						</h2>
						<p class="lead">
							<strong>Why You Can Trust Bullymake Toys</strong>
						</p>
					</div>

					<img class="img-fluid d-block"
						src="https://assets.cdn.bullymake.com/uploads/2024/07/safety-img-desktop-at-2x-668d6c7b6f090.webp"
						alt="" />
				</div>
			</div>
			<!-- END .col-md-6 -->

			<div class="col-md-6 bg-dark text-light">
				<div class="p-5 d-flex flex-column h-100 justify-content-center" style="gap: 15px">
					<div class="d-flex align-items-start" style="gap: 15px">
						<img class="mt-2" width="25"
							src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/yes-icon-check.svg"
							alt="cancel" />
						<div class="lead">
							Rubber & Nylon toys are
							<span class="text-primary">FDA food grade safe</span>, BPA free, contain no lead or
							phthalates, & have
							only natural additives
						</div>
					</div>

					<hr class="w-100" style="border-width: 1px; border-color: #939393" />

					<div class="d-flex align-items-start" style="gap: 15px">
						<img class="mt-2" width="25"
							src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/yes-icon-check.svg"
							alt="cancel" />
						<div class="lead">
							Treats are made in the USA and cater to a
							<span class="text-primary">variety of dog allergy needs</span>
						</div>
					</div>

					<hr class="w-100" style="border-width: 1px; border-color: #939393" />

					<div class="d-flex align-items-start" style="gap: 15px">
						<img class="mt-2" width="25"
							src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/yes-icon-check.svg"
							alt="cancel" />
						<div class="lead">
							Rubber & Nylon toys can
							<span class="text-primary">withstand 3500+ PSI</span>
						</div>
					</div>
				</div>
			</div>
			<!-- END .col-md-6 -->
		</div>
		<!-- END .row -->
	</div>
	<!-- END .container -->
</section>
<!-- END #section-safety -->

<!-- As seen in Section -->
<div class="section-item bg-primary text-white">
	<div class="container">
		<div class="row">
			<div class="col-lg-4">
				<h2 class="text-uppercase text-center text-md-left">
					AS SEEN IN
				</h2>
				<div class="d-none d-md-block mb-lg-0 mb-3">
					<p class="fw-bold" style="font-size: 1.5rem">
						Trusted by <span class="text-dark">1M+ Dog Owners</span>
					</p>
					<a routerLink="/subscribe" class="text-white text-uppercase text-underline lead text-end">Join Millions of Happy
						dogs</a>
				</div>
			</div>
			<div class="col-lg-8">
				<div class="row h-100 mt-3">
					<div class="col-4 d-flex align-items-center justify-content-center">
						<img class="img-fluid"
							src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/cosmo-logo.svg"
							alt="cosmo" />
					</div>
					<div class="col-4 d-flex align-items-center justify-content-center">
						<img class="img-fluid"
							src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/washington-post-logo.svg"
							alt="washington" />
					</div>
					<div class="col-4 d-flex align-items-center justify-content-center">
						<img class="img-fluid"
							src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/forbes-logo.svg"
							alt="forbes" />
					</div>
				</div>
			</div>
		</div>

		<div class="d-md-none d-flex justify-content-center mb-lg-0 mt-4">
			<p class="fw-bold mx-1 small">
				Trusted by <span class="text-primary">1M+ Dog Owners</span>
			</p>
			<a routerLink="/subscribe" class="text-white text-uppercase text-underline mx-1 small">Join Millions of Happy dogs</a>
		</div>
	</div>
</div>

<!-- Customized Theme Section -->
<div class="section-item light-section-7">
	<div class="container">
		<div class="row text-center">
			<div class="col-12">
				<h2 class="text-uppercase mb-3">
					New Customized

					<span class="position-relative mb-5">
						Themes
						<img src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/underline-tough-scribble.svg"
							class="img-fluid position-absolute" style="bottom: -10px; left: 0px" />
					</span>
				</h2>
				<h4 class="mb-4">
					Every Doggone <span class="text-primary">Month</span>
				</h4>
				<div class="row">
					<div class="col-lg-2 col-0"></div>
					<div class="col-lg-8 col-12">
						<p class="lead mb-5 text-center">
							Tell us your dog's weight, allergies, and even toy
							preferences every month for a unique themed box to
							keep you and your pup engaged and happy! Boxes will
							ship the next day and every 30 days after.
						</p>
					</div>
					<div class="col-lg-2 col-0"></div>
				</div>
			</div>

			<div class="col-12">
				<div class="row d-none d-lg-flex">
					<div class="col-lg-4 mb-5">
						<img class="img-fluid"
							src="https://assets.cdn.bullymake.com/uploads/2024/12/dec-2024-674cabc5689cd.webp" />
					</div>

					<div class="col-lg-4 mb-5">
						<img class="img-fluid"
							src="https://assets.cdn.bullymake.com/uploads/2024/11/november-box-6723153b86f38.webp" />
					</div>

					<div class="col-lg-4 mb-5">
						<img class="img-fluid"
							src="https://assets.cdn.bullymake.com/uploads/2024/10/october-box-66fc2a15bacb4.webp" />
					</div>

					<div class="col-12">
						<a class="btn btn-primary btn-lg mr-4" routerLink="/subscribe" href="#plans">Subscribe Now</a>
					</div>
				</div>

				<div class="row d-flex d-lg-none position-relative">
					<div class="col-12">
						<div class="row overflow-scroll flex-nowrap hide-scrollbar smooth-scroll" #scrollContainer>
							<div class="col-7 mb-5">
								<img width="350px" class="img-fluid"
									src="https://assets.cdn.bullymake.com/uploads/2024/12/dec-2024-674cabc5689cd.webp" />
							</div>

							<div class="col-7 mb-5">
								<img width="350px" class="img-fluid"
									src="https://assets.cdn.bullymake.com/uploads/2024/11/november-box-6723153b86f38.webp" />
							</div>

							<div class="col-7 mb-5">
								<img width="350px" class="img-fluid"
									src="https://assets.cdn.bullymake.com/uploads/2024/10/october-box-66fc2a15bacb4.webp" />
							</div>
						</div>
					</div>
					<div class="col-12">
						<div class="d-flex align-items-center justify-content-center">
							<button class="slider-button rounded mr-5" (click)="scroll(scrollContainer, -200)">
								<img
									src="data:image/svg+xml,%3Csvg width='22' height='38' viewBox='0 0 22 38' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_0_39547' style='mask-type:luminance' maskUnits='userSpaceOnUse' x='0' y='0' width='22' height='38'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M21.5747 37.575H0.574707L0.574707 0.574951H21.5747L21.5747 37.575Z' fill='white'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_0_39547)'%3E%3Cpath opacity='0.25' fill-rule='evenodd' clip-rule='evenodd' d='M18.8204 0.574951C19.5254 0.574951 20.2297 0.847915 20.7681 1.39315C21.8436 2.48291 21.8436 4.25195 20.7681 5.34171L7.22392 19.0751L20.7681 32.8078C21.8436 33.8983 21.8436 35.6666 20.7681 36.7571C19.692 37.8476 17.9487 37.8476 16.8726 36.7571L1.38129 21.0494C0.305846 19.9589 0.305846 18.1906 1.38129 17.1001L16.8726 1.39315C17.411 0.847915 18.1153 0.574951 18.8204 0.574951' fill='%23382615'/%3E%3C/g%3E%3C/svg%3E%0A" />
							</button>

							<button class="slider-button rounded" (click)="scroll(scrollContainer, 200)">
								<img
									src="data:image/svg+xml,%3Csvg width='22' height='38' viewBox='0 0 22 38' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_0_40369' style='mask-type:luminance' maskUnits='userSpaceOnUse' x='0' y='0' width='22' height='38'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.643738 0.356201H21.6437V37.3562H0.643738V0.356201Z' fill='white'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_0_40369)'%3E%3Cpath opacity='0.25' fill-rule='evenodd' clip-rule='evenodd' d='M3.39809 37.3562C2.69306 37.3562 1.98873 37.0832 1.45032 36.538C0.374878 35.4482 0.374878 33.6792 1.45032 32.5894L14.9945 18.856L1.45032 5.12331C0.374878 4.03285 0.374878 2.26451 1.45032 1.17405C2.52645 0.0835853 4.26973 0.0835853 5.34586 1.17405L20.8372 16.8817C21.9126 17.9722 21.9126 19.7405 20.8372 20.831L5.34586 36.538C4.80745 37.0832 4.10312 37.3562 3.39809 37.3562' fill='%23382615'/%3E%3C/g%3E%3C/svg%3E%0A" />
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- Compare Section -->
<div class="section-item light-section-8 overflow-hidden">
	<div class="container">
		<div class="row text-center">
			<div class="col-12">
				<h2 class="text-uppercase mb-5">
					<span class="position-relative">
						<img src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/heading-wag-illustration-left.svg"
							class="img-fluid position-absolute" style="top: -28px; left: -28px" />
						See
					</span>
					How We
					<span class="position-relative mb-5">
						Compare
						<img src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/circle-compare-scribble.svg"
							class="img-fluid position-absolute" style="top: 0px; left: 0px" />
					</span>
				</h2>
			</div>

			<div class="col-12 mb-5">
				<div class="table-responsive card shadow">
					<table class="mb-0 table table-compare table-striped table-dark table-borderless">
						<thead class="text-uppercase">
							<tr>
								<th class="w-50"></th>
								<th class="w-25 border-x vertically-middle">
									<div class="d-flex justify-content-center flex-wrap align-items-center">
										<img class="img-fluid mr-2 mb-2"
											src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/bmk-logomark-grey.svg"
											alt="grey bullymake logo" />
										<h3 class="m-0 p-0 d-lg-block d-none">
											Bullymake
										</h3>
									</div>
								</th>
								<th class="w-25 vertically-middle">
									<p class="lead pb-0 mb-0 d-lg-none">
										Other
									</p>
									<p class="lead pb-0 mb-0 d-lg-block d-none">
										Other pet <br />subscription boxes
									</p>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<th class="vertically-middle text-start">
									<p class="lead m-0 px-4">
										Made in the USA*
									</p>
								</th>
								<td class="border-x vertically-middle">
									<img class="img-fluid"
										src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/yes-icon-check.svg"
										alt="cancel" />
								</td>
								<td class="vertically-middle">
									<img class="img-fluid"
										src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/no-icon-x.svg"
										alt="cancel" />
								</td>
							</tr>

							<tr>
								<th class="vertically-middle text-start">
									<p class="lead m-0 px-4">
										Non-toxic, Phthalate-free, FDA Approved
										Materials
									</p>
								</th>
								<td class="border-x vertically-middle">
									<img class="img-fluid"
										src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/yes-icon-check.svg"
										alt="cancel" />
								</td>
								<td class="vertically-middle">
									<img class="img-fluid"
										src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/no-icon-x.svg"
										alt="cancel" />
								</td>
							</tr>

							<tr>
								<th class="vertically-middle text-start">
									<p class="lead m-0 px-4">
										Complete Box Customization*
									</p>
								</th>
								<td class="border-x vertically-middle">
									<img class="img-fluid"
										src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/yes-icon-check.svg"
										alt="cancel" />
								</td>
								<td class="vertically-middle">
									<img class="img-fluid"
										src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/no-icon-x.svg"
										alt="cancel" />
								</td>
							</tr>

							<tr>
								<th class="vertically-middle text-start">
									<p class="lead m-0 px-4">
										Number of Items per Monthly Box 5 Items
									</p>
								</th>
								<td class="border-x vertically-middle">
									<p class="lead m-0 px-4">5 Items</p>
								</td>
								<td class="vertically-middle">
									<p class="lead m-0 px-4">4 Items</p>
								</td>
							</tr>

							<tr>
								<th class="vertically-middle text-start">
									<p class="lead m-0 px-4">
										100% Tough Chew or Satisfaction
										Guarantee
									</p>
								</th>
								<td class="border-x vertically-middle">
									<img class="img-fluid"
										src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/yes-icon-check.svg"
										alt="cancel" />
								</td>
								<td class="vertically-middle">
									<img class="img-fluid"
										src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/yes-icon-check.svg"
										alt="cancel" />
								</td>
							</tr>

							<tr>
								<th class="vertically-middle text-start">
									<p class="lead m-0 px-4">
										Scented & Treat Dispensing Chews for
										Increased Satisfaction
									</p>
								</th>
								<td class="border-x vertically-middle">
									<img class="img-fluid"
										src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/yes-icon-check.svg"
										alt="cancel" />
								</td>
								<td class="vertically-middle">
									<img class="img-fluid"
										src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/yes-icon-check.svg"
										alt="cancel" />
								</td>
							</tr>

							<!-- <tr>
								<th class="vertically-middle text-start">
									<p class="lead m-0 px-4">
										Free Shipping on ALL Multi-Month Plans
									</p>
								</th>
								<td class="border-x vertically-middle">
									<img class="img-fluid "
										src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/yes-icon-check.svg" alt="cancel">
								</td>
								<td class="vertically-middle">
									<img class="img-fluid " src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/no-icon-x.svg"
										alt="cancel">
								</td>
							</tr> -->
						</tbody>
					</table>
				</div>
			</div>

			<div class="col-12">
				<div class="bg-white position-relative py-4 px-5">
					<p class="text-center px-md-5">
						Our toughest toys, Nylon and Rubber, are made in the USA.
						All of our treats are made in the USA. Choose a
						mix of toys and treats, toys only, or even combine
						softer plush options along with our POWER CHEWER toys.
					</p>
					<img style="
							right: -120px;
							top: 50%;
							transform: translateY(-50%);
						" class="position-absolute"
						src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/comparison-chart-sketch.svg"
						alt="trophy" />
				</div>
			</div>
		</div>
	</div>
</div>

<!-- Ready to join Section -->
<section class="section-item light-section-9 mb-5 pb-0">
	<div class="container">
		<div class="row">
			<div class="col-5">
				<div class="column-content bg-inkblot" style="position: relative; height: 100%">
					<img class="img-fluid" style="position: absolute; bottom: 0px; left: -120px"
						src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/inkblot.svg"
						alt="" />
					<img class="img-fluid" style="position: absolute; bottom: 0px"
						src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/subscribe-dog.webp"
						alt="" />
				</div>
			</div>

			<div class="col-7" style="padding-bottom: 5rem">
				<h2 class="d-inline-block mr-2 align-middle">
					<span class="text-light d-block">READY TO JOIN TOUGH CHEWERS?</span>
				</h2>

				<a routerLink="/subscribe" class="d-inline-block btn btn-primary btn-lg text-uppercase mb-2">Subscribe Now</a>

				<!-- <div class="row">
					<div class="col-8 d-lg-flex d-none">
						<input type="text" placeholder="Your Chewer's Name?"
							class="form-control chewer-name-control py-4 ">
					</div>
					<div class="col-lg-4">

					</div>
				</div> -->
			</div>
		</div>
	</div>
</section>

<!-- CU Section -->
<section class="section-item pt-0 light-section-10">
	<div class="container position-relative pt-3 pt-sm-4">
		<div class="section-header d-flex">
			<div class="bg-primary mr-4 d-flex align-items-center justify-content-center"
				style="width: 90px; height: 90px">
				<img width="60px" height="60px" class="img-fluid"
					src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/bullymake-tag.webp"
					alt="bullymake logo" />
			</div>
			<div>
				<h2 class="text-uppercase mb-0 pb-0">
					Chewers
					<span class="position-relative">Unite
						<img class="position-absolute img-fluid" style="
								right: -40px;
								top: -26px;
								transform: rotate(80deg);
							" src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/heading-wag-illustration-left.svg"
							alt="heading wag" />
					</span>
				</h2>
				<p class="fw-bold extra-lead">
					Show us your happy chewers photos
					<span class="text-primary">#bullymake</span>
				</p>
			</div>
		</div>

		<div id="instagramCarousel" class="mt-5 carousel-wrapper style-1">
			<carousel [showIndicators]="false" class="d-none d-md-block">
				<slide>
					<div class="row">
						<div class="col-3">
							<img class="d-inline-block shadow img-fluid"
								src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/intagram-slide-1.webp"
								alt="blockquote" />
						</div>
						<!-- END .col-3 -->
						<div class="col-3">
							<img class="d-inline-block shadow img-fluid"
								src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/intagram-slide-2.webp"
								alt="blockquote" />
						</div>
						<!-- END .col-3 -->
						<div class="col-3">
							<img class="d-inline-block shadow img-fluid"
								src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/intagram-slide-3.webp"
								alt="blockquote" />
						</div>
						<!-- END .col-3 -->
						<div class="col-3">
							<img class="d-inline-block shadow img-fluid"
								src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/intagram-slide-4.webp"
								alt="blockquote" />
						</div>
						<!-- END .col-3 -->
					</div>
					<!-- END .row -->
				</slide>

				<slide>
					<div class="row">
						<div class="col-3">
							<img class="d-inline-block shadow img-fluid"
								src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/intagram-slide-5.webp"
								alt="blockquote" />
						</div>
						<!-- END .col-3 -->
						<div class="col-3">
							<img class="d-inline-block shadow img-fluid"
								src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/intagram-slide-6.webp"
								alt="blockquote" />
						</div>
						<!-- END .col-3 -->
						<div class="col-3">
							<img class="d-inline-block shadow img-fluid"
								src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/intagram-slide-7.webp"
								alt="blockquote" />
						</div>
						<!-- END .col-3 -->
						<div class="col-3">
							<img class="d-inline-block shadow img-fluid"
								src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/intagram-slide-1.webp"
								alt="blockquote" />
						</div>
						<!-- END .col-3 -->
					</div>
					<!-- END .row -->
				</slide>
			</carousel>

			<carousel [showIndicators]="false" class="d-md-none d-block">
				<slide>
					<img class="d-block w-100 shadow img-fluid"
						src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/intagram-slide-1.webp"
						alt="blockquote" />
				</slide>

				<slide>
					<img class="d-block w-100 shadow img-fluid"
						src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/intagram-slide-2.webp"
						alt="blockquote" />
				</slide>

				<slide>
					<img class="d-block w-100 shadow img-fluid"
						src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/intagram-slide-3.webp"
						alt="blockquote" />
				</slide>

				<slide>
					<img class="d-block w-100 shadow img-fluid"
						src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/intagram-slide-4.webp"
						alt="blockquote" />
				</slide>

				<slide>
					<img class="d-block w-100 shadow img-fluid"
						src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/intagram-slide-5.webp"
						alt="blockquote" />
				</slide>

				<slide>
					<img class="d-block w-100 shadow img-fluid"
						src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/intagram-slide-6.webp"
						alt="blockquote" />
				</slide>
			</carousel>
		</div>
		<!-- END #testimonialCarousel -->
	</div>
	<!-- END .container -->
</section>
<!-- END .section-item -->
