import { Component, OnInit, OnDestroy, Renderer2, ElementRef, ViewChild, AfterViewInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UpsellDialogComponent } from '../../../layout/dialogs/upsell-dialog/upsell-dialog.component';
import { ProductsService, CartService } from '../../../core/services';

import { map } from 'rxjs/operators';
import { GoogleReviewsService } from '../reviews/google-reviews.service';
import { TrackingService } from '../../my-account/tracking/tracking.service';
import { API_Response, IProduct, Cart, Checkout } from 'bullymake-types';
import { HttpErrorResponse } from '@angular/common/http';
import { Meta } from '@angular/platform-browser';

@Component({
	selector: 'app-single-product',
	templateUrl: './single-product.component.html',
	styleUrls: ['./single-product.component.css']
})
export class SingleProductComponent implements OnInit, OnDestroy,AfterViewInit {

	@ViewChild('productFooter') productFooter: ElementRef;

	@ViewChild('carousel') carousel: ElementRef;

	public reviews:Array<object> = [];

	public cart: Cart;

	public promotionDetails: any = false;

	public promotion: any = false;

	public id: string;

	public userEmailInvalid = false;

	public dogNameInvalid = false;

	public capturingEmail = false;

	public userEmail: string = '';

	public dogName: string = '';

	public itemsPerSlide = 3;

	public customizePath: string;

	public product: IProduct;

	public slides: Array<Object>;

	public body:HTMLBodyElement;

	public footer:HTMLDivElement;

	public isLoading: boolean = false;

	public metaDescriptions:object = {
		"1-month-subscription":"Subscribe to BULLYMAKE's 1-Month Dog Box for power chewers. Durable toys and tasty treats tailored to your dog's needs. Try it today!",
		"1-year-subscription":"Subscribe to BULLYMAKE's 1-Year Dog Toy subscription for durable toys built for power chewers. Long-lasting fun delivered straight to your door!",
		"3-month-subscription":"Subscribe to BULLYMAKE's 3-Month Dog Box for power chewers. Tough dog toys and tasty treats tailored for tough chewers. Try it today!",
		"6-month-subscription":"Subscribe to BULLYMAKE's 6-Month Plan for durable dog toys designed for large dogs. Tough, long-lasting, and perfect for power chewers!",
		"monthly-pay-plan":"Try BULLYMAKE's durable dog toys with our 1-month subscription box. Perfect for tough chewers who need long-lasting fun!"
	}

	public innerWidth:number = window.innerWidth;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private productsService: ProductsService,
		private trackingService: TrackingService,
		private cartService: CartService,
		private dialog: MatDialog,
		private googleReviewsService: GoogleReviewsService,
		private renderer2:Renderer2,
		private meta:Meta
		) {

			this.meta.updateTag(
				{
					name: "description",
					content: this.metaDescriptions[this.route.snapshot.url[1].path]
				}
			)

		this.body = document.body as HTMLBodyElement;

		this.footer = document.getElementById("footer") as HTMLDivElement;

		// OLD
		if (this.route.snapshot.url.length == 3 && this.route.snapshot.url[2].path == 'promotion') {
			this.promotion = 'a';
		}

		if (this.route.snapshot.url.length == 3 && this.route.snapshot.url[2].path == 'promotion-b') {
			this.promotion = 'b';
		}

		if (this.route.snapshot.url.length == 3 && this.route.snapshot.url[2].path == 'promotion-c') {
			this.promotion = 'c';
		}

		if (this.route.snapshot.url.length == 3 && this.route.snapshot.url[2].path == 'promotion-fb20') {
			this.promotion = 'fb20';
		}
		// END OLD

		this.cart = {
			product_id: route.snapshot.data.id,
			quantity: 1,
			coupon: '',
			selection: this.productsService.getDefaultSelection()
		};

		this.id = route.snapshot.data.id;
		this.product = this.productsService.getProduct(this.id);

		// OLD
		if (this.promotion && this.product.promotions[this.promotion]) {

			this.promotionDetails = this.product.promotions[this.promotion];
			this.cart.coupon = this.promotionDetails.coupon;
		}
		// END OLD

		// this.route.params.subscribe(params => {
		//   if( params.coupon ) {
		//     this.cart.coupon = params.coupon;
		//
		//     if( params.coupon.strtoupper == 'FREETOY' ) {
		//
		//     }
		//   }
		//
		// });
	}

	// For making Google Review Carousal Responsive
	@HostListener('window:resize', ['$event'])

	onResize(event) {

	 this.innerWidth= event.target.innerWidth;



	}

	ngOnInit() {

		this.renderer2.addClass(this.body,'single-product');

		// fbq('track', 'InitiateCheckout');
		// fbq('track', 'pageview');

		// GA Enhanced Ecommerce - product impression
		// @ts-ignore
		window.dataLayer.push({
			'ecommerce': {
				'currencyCode': 'USD',
				'impressions': [
					{
						'name': this.product.fullPrice,
						'id': this.id.toString(),
						'price': this.product.price.toString()
					}
				]
			}
		});

		// @ts-ignore
		window.dataLayer.push({
			'event': 'productView',
			'ecommerce': {
				'impressions': [{ 'name': this.product.fullPrice }],
			}
		});

		// @ts-ignore
		_learnq.push(['track', 'Viewed Product', {
			'ProductName': this.product.fullPrice,
			'ProductID': this.id,
			'Brand': 'Bullymake',
			'Price': this.product.price,
		}]);



		this.getGoogleReviews();

		// @ts-ignore
		if (typeof attentive != 'undefined') {
			// @ts-ignore
			attentive.analytics.productView(
				{
					items: [
						{
							productId: this.id,
							productVariantId: this.id,
							name: 'Bullymake Box - ' + this.product.duration + ' ' + this.product.period + ' Subscription',
							productImage: 'https://bullymake.com/' + this.product.slides[0].url,
							category: 'Dog Product',
							price: {
								value: this.product.price,
								currency: 'USD',
							},
							quantity: 1,
						}
					]
				}
			);
		}

	}

	getGoogleReviews() {
		this.googleReviewsService.getReviews(true, 0)
			.subscribe(response => {
				this.reviews = response.body.reviews;
			});
	}

	ngOnDestroy(): void {

		this.renderer2.removeStyle(this.footer,"marginBottom");

		this.renderer2.removeClass(this.body,'single-product');

	}

	ngAfterViewInit(){

		const productFooterOffsetHt = this.productFooter.nativeElement.offsetHeight

		this.renderer2.setStyle(this.footer,"marginBottom",productFooterOffsetHt+"px");

	}


	// upsellDialog() {
	//
	//   const dialogConfig = new MatDialogConfig();
	//         dialogConfig.panelClass = 'upsellDialogPanel';
	//
	//   let dialogRef = this.dialog.open(UpsellDialogComponent, dialogConfig);
	//
	//    dialogRef.afterClosed().subscribe(value => {
	//
	//    });
	// }


	/**
	 * Update Cart Object
	 * @param cart Cart
	 * @return void
	 */
	updateCart(cart: Cart) {

		// Save to local storage
		localStorage.setItem('__bm_cart', JSON.stringify(cart));

		this.isLoading = true;

		this.cartService.updateCart(cart)
			.pipe(
				map((response: API_Response<Checkout>): Checkout => {

					this.isLoading = false;

					return response.data;
				})
			)
			.subscribe((cart: Checkout) => {

				this.router.navigate(['/checkout']);

			}, (err: HttpErrorResponse) => {

				this.isLoading = false;
			});

		return;

		// this.cart = {
		// 	product_id: route.snapshot.data.id,
		// 	quantity: 1,
		// 	selection: this.productsService.getDefaultSelection()
		// };
		// this.router.navigate(['/subscribe'], {
		// 	queryParams: {...(cart.coupon && {promotion: cart.coupon})},
		// });

		return;


		const dialogConfig = new MatDialogConfig();
		dialogConfig.panelClass = 'upsell-dialog-panel';
		const dialogRef = this.dialog.open(UpsellDialogComponent, dialogConfig);

		dialogRef.afterClosed().subscribe(value => {
			cart.selection.add_extra_toy = value;

			this.cartService.updateCart(cart)
				.pipe(
					map((response: API_Response<Checkout>): Checkout => {

						return response.data;
					})
				)
				.subscribe((data: Checkout) => {

					// @ts-ignore
					fbq('track', 'AddToCart');

					this.trackingService.gtm('AddToCart');

					// @ts-ignore
					snaptr('track', 'ADD_CART');

					// @ts-ignore
					_learnq.push(['track', 'Added to Cart', {
						'$value': this.product.price * cart.quantity,
						'AddedItemProductName': this.product.fullPrice,
						'AddedItemProductID': this.id,
						'AddedItemPrice': this.product.price,
						'AddedItemQuantity': cart.quantity,
						'ItemNames': [this.product.fullPrice],
						'CheckoutURL': 'https://bullymake.com/checkout',
						'Items': [{
							'ProductID': this.id,
							'ProductName': this.product.fullPrice,
							'Quantity': cart.quantity,
							'ItemPrice': this.product.price,
							'RowTotal': this.product.price * cart.quantity,
						},
						]
					}]);

					// GA Enhanced Ecommerce - add to cart
					// @ts-ignore
					window.dataLayer.push({
						'event': 'addToCart',
						'ecommerce': {
							'currencyCode': 'USD',
							'add': {
								'products': [{
									'name': this.product.fullPrice,
									'id': this.id,
									'price': this.product.price
								}]
							}
						}
					});

					// @ts-ignore
					if (typeof attentive != 'undefined') {
						// @ts-ignore
						attentive.analytics.addToCart(
							{
								items: [
									{
										productId: this.id,
										productVariantId: this.id,
										name: 'Bullymake Box - ' + this.product.duration + ' ' + this.product.period + ' Subscription',
										productImage: 'https://bullymake.com/' + this.product.slides[0].url,
										category: 'Dog Product',
										price: {
											value: this.product.price,
											currency: 'USD',
										},
										quantity: 1,
									}
								]
							}
						);
					}


					// TikTok
					// @ts-ignore
					if (typeof ttq !== 'undefined') { ttq.track('AddToCart'); }

					// if (this.userService.getLocalUser()) {
					// 	this.router.navigate(['/checkout']);
					// } else {

					// 	// this.showEmailCapture = true;
					// 	this.renderer2.removeStyle(this.footer,"marginBottom");
					// 	this.setFooterBottom();

					// 	this.router.events.subscribe(event => {
					// 		if (event instanceof NavigationStart) {
					// 			this.freeFooter();
					// 		}
					// 	});
					// }
				});
		});
	}

	setFooterBottom() {
		this.renderer2.setStyle(this.footer.parentElement,"position","absolute");
		this.renderer2.setStyle(this.footer.parentElement,"width","100%");
		this.renderer2.setStyle(this.footer.parentElement,"bottom","0px");
	}

	addQty(cart: Cart) {

		cart.quantity++;
	}

	removeQty(cart: Cart) {

		if (cart.quantity <= 1) { return; }

		cart.quantity--;
	}

	freeFooter() {
		this.renderer2.removeStyle(this.footer.parentElement,"position");
		this.renderer2.removeStyle(this.footer.parentElement,"width");
		this.renderer2.removeStyle(this.footer.parentElement,"bottom");
	}

	captureEmail() {

		const userEmail = this.userEmail.trim();

		this.userEmailInvalid = !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(userEmail);
		this.dogNameInvalid = this.dogName === null;

		if (!this.userEmailInvalid && !this.dogNameInvalid) {
			this.capturingEmail = true;

			localStorage.setItem('userEmail', userEmail);
			localStorage.setItem('dogName', this.dogName);

			this.trackingService.addEmailToKlaviyo({ email: userEmail, dogName: this.dogName })
				.subscribe(() => {

				});

			this.freeFooter();
			this.router.navigate(['/checkout']);
		}
	}
}
