import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ProductsService, CartService } from '../../../../core/services';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { HelpVideoComponent } from '../../../../layout/dialogs/help-video/help-video.component';
import { Cart, Checkout, API_Response } from 'bullymake-types';
import { map } from 'rxjs/operators';
import { Meta } from '@angular/platform-browser';


export interface DialogData {
	animal: string;
	name: string;
}

@Component({
	selector: 'app-customize',
	templateUrl: './customize.component.html',
	styleUrls: ['./customize.component.css']
})
export class CustomizeComponent implements OnInit {

	animal: string;
	name: string;
	fields;
	selection;

	cart;
	unit;

	public metaDescriptions:object = {
		"1-month-subscription":"Customize your BULLYMAKE Dog Box with durable toys tailored for extreme chewers. Designed for tough play and lasting enjoyment!",
		"3-month-subscription":"Try BULLYMAKE's durable dog toys with a 3-month subscription. Perfect for tough chewers who need long-lasting fun and tailored treats!",
		"1-year-subscription":"Customize your BULLYMAKE Box with tough chew toys for dogs. Durable, safe, and perfect for power chewers. Tailor it to your pup’s needs today!",
		"6-month-subscription":"Customize your 6-month BULLYMAKE Box with the toughest dog toys. Durable and safe for power chewers. Perfect for your dog's needs!",
		"monthly-pay-plan":"Try BULLYMAKE's dog toys for aggressive chewers with a 1-month box. Durable, long-lasting fun tailored for your tough chewer!"
	}

	constructor(
		public router: Router,
		public route: ActivatedRoute,
		private dialog: MatDialog,
		private productsService: ProductsService,
		private cartService: CartService,
		private meta:Meta
	) {
			this.meta.updateTag(
				{
					name: "description",
					content: this.metaDescriptions[this.route.snapshot.url[1].path]
				}
			)

		this.cart = {
			product_id: route.snapshot.data.id,
			quantity: 1,
			selection: this.productsService.getDefaultSelection({})
		};
	}

	ngOnInit() {

		this.fields = this.productsService.getFields();

		this.cart.selection.custom_box = true;
	}

	getFields() {

	}

	openDialog() {

		const dialogConfig = new MatDialogConfig();

		let dialogRef = this.dialog.open(HelpVideoComponent, dialogConfig);

		dialogRef.afterClosed().subscribe(value => {

		});
	}

	updateUnit(value, selection) {

		if (value.checked == true) { selection.unit = 'kg'; }

		if (value.checked == false) { selection.unit = 'lbs'; }
	}

	updateCart(cart: Cart) {

		this.cartService.updateCart(cart)
			.pipe(
				map((res: API_Response<Checkout>): Checkout => {

					return res.data;
				}))
			.subscribe((data: Checkout) => {

				this.router.navigate(['/checkout']);
			});
	}
}
