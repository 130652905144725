import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UnsubscribeService } from './unsubscribe.service';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-help',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.css']
})
export class UnsubscribeComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private unsubscribeService: UnsubscribeService,
	private meta:Meta
  ) {

	this.meta.updateTag(
		{
			name: "description",
			content: "Manage your preferences and unsubscribe from BULLYMAKE emails. Stay in control of your inbox with just a few clicks."
		}
	)
  }

  ngOnInit() {
    const userEmail = this.route.snapshot.queryParams['e'] || null;

    if (userEmail) {
      this.unsubscribeService.process(userEmail).subscribe();
    }
  }

}
