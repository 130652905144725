import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import { RemovalService } from './removal.service';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-help',
  templateUrl: './removal.component.html',
  styleUrls: ['./removal.component.css']
})
export class RemovalComponent implements OnInit {

  removalForm: UntypedFormGroup;
  loader: boolean = false;
  step: number = 1;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private removalService: RemovalService,
	private meta:Meta
  ) { }

  ngOnInit() {

	this.meta.updateTag(
		{
			name: "description",
			content: "Learn how to remove your personal data from BULLYMAKE's email list. Manage your preferences and stay in control of your information."
		}
	)
    this.removalForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });

    const userEmail:string = this.route.snapshot.queryParams['e'] || null;

    if (userEmail) {
      this.step = 3;
      document.cookie.split(';').forEach(
        function(c) { document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/'); }
      );
      localStorage.clear();
      this.removalService.confirm(userEmail).subscribe();
    }
  }

  submitEmail() {
    this.loader = true;
    this.removalService.process(this.removalForm.getRawValue().email).subscribe(() => {
      this.step = 2;
    });
  }

}
