import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-subscription-box-a',
  templateUrl: './subscription-box-a.component.html',
  styleUrls: ['./subscription-box-a.component.css']
})
export class SubscriptionBoxAComponent implements OnInit {

  constructor(private meta:Meta) {

	this.meta.updateTag(
		{
			name: "description",
			content: "Get the BULLYMAKE Subscription Box packed with durable toys for extreme chewers. Built to last and delivered monthly to your door!"
		}
	)

  }

  ngOnInit() {
  }

}
