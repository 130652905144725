import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./core/guards/auth.guard";
import { UnsubscribeComponent } from "./modules-page/main/unsubscribe/unsubscribe.component";
import { RemovalComponent } from "./modules-page/main/removal/removal.component";
import { BullymakeBlogComponent } from "./modules-page/main/bullymake-blog/bullymake-blog.component";
import { SingleProductComponent } from "./modules-page/main/single-product/single-product.component";
import { CustomizeComponent } from "./modules-page/main/single-product/customize/customize.component";
import { ReviewsComponent } from "./modules-page/main/reviews/reviews.component";
import { DogToyPComponent } from "./modules-page/main/landing/dog-toy-p/dog-toy-p.component";
import { DogToyP2Component } from "./modules-page/main/landing/dog-toy-p2/dog-toy-p2.component";
import { SinglePostComponent } from "./modules-page/main/bullymake-blog/partials/single-post/single-post.component";
import { DogBoredomComponent } from "./modules-page/main/landing/dog-boredom/dog-boredom.component";
import { DogGiftComponent } from "./modules-page/main/landing/dog-gift/dog-gift.component";
import { WhyBullymakeAComponent } from "./modules-page/main/why-bullymake/why-bullymake-a/why-bullymake-a.component";
import { WhyBullymakeBComponent } from "./modules-page/main/why-bullymake/why-bullymake-b/why-bullymake-b.component";
import { WhyBullymakeCComponent } from "./modules-page/main/why-bullymake/why-bullymake-c/why-bullymake-c.component";
import { DogToyAComponent } from "./modules-page/main/dog-toy/dog-toy-a/dog-toy-a.component";
import { DogToyBComponent } from "./modules-page/main/dog-toy/dog-toy-b/dog-toy-b.component";
import { DogToyB2Component } from "./modules-page/main/dog-toy/dog-toy-b2/dog-toy-b2.component";
import { DogToyCComponent } from "./modules-page/main/dog-toy/dog-toy-c/dog-toy-c.component";
import { DogToyB20Component } from "./modules-page/main/dog-toy/dog-toy-b20/dog-toy-b20.component";
import { SubscriptionBoxAComponent } from "./modules-page/main/subscription-box/subscription-box-a/subscription-box-a.component";
import { SubscriptionBoxBComponent } from "./modules-page/main/subscription-box/subscription-box-b/subscription-box-b.component";
import { SubscriptionBoxB2Component } from "./modules-page/main/subscription-box/subscription-box-b2/subscription-box-b2.component";
import { SubscriptionBoxCComponent } from "./modules-page/main/subscription-box/subscription-box-c/subscription-box-c.component";
import { SubscriptionBoxB20Component } from "./modules-page/main/subscription-box/subscription-box-b20/subscription-box-b20.component";
import { PromotionsComponent } from "./modules-page/main/promotions/promotions.component";
import { LoginComponent } from "./modules-page/main/login/login.component";
import { BedsComponent } from "./modules-page/main/beds/beds.component";
import { HomeComponent } from "./pages/home/home.component";
import { BlackFridayComponent } from "./pages/black-friday/black-friday.component";
import { AffiliateComponent } from "./pages/affiliate/affiliate.component";

const appRoutes: Routes = [
	{
		path: "",
		component: HomeComponent,
		title: "Shop BULLYMAKE Indestructible Dog Toys for Tough Chewers",
	},
	{
		path: "subscribe",
		loadChildren: () =>
			import("./modules-page/subscribe/subscribe.module").then(
				(m) => m.SubscribeModule
			),
		title: "Get the BULLYMAKE Dog Subscription Box for Tough Chewers",
	},
	{
		path: "in-the-box",
		loadComponent: () =>
			import("./pages/in-the-box/in-the-box.component").then(
				(x) => x.InTheBoxComponent
			),
		title: "Discover What's in the BULLYMAKE Dog Box | Tough Dog Toys",
	},
	{
		path: "past-boxes",
		loadComponent: () =>
			import("./pages/past-boxes/past-boxes.component").then(
				(x) => x.PastBoxesComponent
			),
		title: "Explore Past BULLYMAKE Boxes | Dog Toys for Heavy Chewers",
	},
	{
		path: "contact-us",
		loadComponent: () =>
			import("./pages/contact-us/contact-us.component").then(
				(x) => x.ContactUsComponent
			),
		title: "Contact BULLYMAKE Customer Service | Get in Touch!",
	},
	{
		path: "faq",
		loadComponent: () =>
			import("./pages/faqs/faqs.component").then((x) => x.FaqsComponent),
		title: "Frequently Asked Questions | BULLYMAKE Dog Chew Toys",
	},
	{
		path: "dog-toy",
		component: DogToyAComponent,
		title: "Best Dog Toys for Aggressive Chewers | BULLYMAKE",
	},
	{
		path: "dog-toy-b",
		component: DogToyBComponent,
		title: "Best Dog Toys for Aggressive Chewers | BULLYMAKE",
	},
	{
		path: "dog-toy-b2",
		component: DogToyB2Component,
		title: "Best Dog Toys for Aggressive Chewers | BULLYMAKE",
	},
	{
		path: "dog-toy-20",
		component: DogToyB20Component,
		title: "Best Dog Toys for Aggressive Chewers | BULLYMAKE",
	},
	{
		path: "dog-toy-c",
		component: DogToyCComponent,
		title: "Best Dog Toys for Aggressive Chewers | BULLYMAKE",
	},
	{
		path: "dog-toy-p",
		component: DogToyPComponent,
		title: "Best Dog Toys for Aggressive Chewers | BULLYMAKE",
	},
	{
		path: "dog-toy-p2",
		component: DogToyP2Component,
		title: "Best Dog Toys for Aggressive Chewers | BULLYMAKE",
	},
	{
		path: "reviews",
		component: ReviewsComponent,
		title: "Customer Reviews of BULLYMAKE Dog Toys and Treats Box",
	},
	{
		path: "product/1-month-subscription",
		component: SingleProductComponent,
		title: "Subscribe to BULLYMAKE 1-Month Dog Box for Power Chewers",
		data: { id: 3276883 },
	},
	{
		path: "product/3-month-subscription",
		component: SingleProductComponent,
		title: "Subscribe to BULLYMAKE's 3-Month Dog Box for Power Chewers",
		data: { id: 3276878 },
	},
	{
		path: "product/6-month-subscription",
		component: SingleProductComponent,
		title: "Subscribe to BULLYMAKE's 6-Month Plan | Toys for Large Dogs",
		data: { id: 3276880 },
	},
	{
		path: "product/1-year-subscription",
		component: SingleProductComponent,
		title: "Subscribe to BULLYMAKE's 1-Year Durable Dog Toy Box",
		data: { id: 3276881 },
	},
	{
		path: "product/monthly-pay-plan",
		component: SingleProductComponent,
		title: "Try BULLYMAKE Durable Dog Toys for 1 Month | Dog Box",
		data: { id: 59533 },
	},
	{
		path: "product/1-month-subscription/customize", // TODO: Remove this
		component: CustomizeComponent,
		title: "Customize Your BULLYMAKE Dog Toys for Extreme Chewers",
		data: { id: 3276883 },
	},
	{
		path: "product/3-month-subscription/customize",
		component: CustomizeComponent,
		title: "Try BULLYMAKE Durable Dog Toys for 3-Months",
		data: { id: 3276878 },
	},
	{
		path: "product/6-month-subscription/customize",
		component: CustomizeComponent,
		title: "Customize Your 6-Month BULLYMAKE Box | Toughest Dog Toys",
		data: { id: 3276880 },
	},
	{
		path: "product/1-year-subscription/customize",
		component: CustomizeComponent,
		title: "Customize Your BULLYMAKE Tough Chew Toys for Dogs Box",
		data: { id: 3276881 },
	},
	{
		path: "product/monthly-pay-plan/customize",
		component: CustomizeComponent,
		title: "Try BULLYMAKE Aggressive Chewer Dog Toys for 1-Month!",
		data: { id: 59533 },
	},
	{
		path: "product/1-month-subscription/promotion",
		component: SingleProductComponent,
		title: "1 Month Subscription - Bullymake Box - A Dog Subscription Box For Power Chewers!",
		data: { id: 3276883 },
	},
	{
		path: "product/1-month-subscription/promotion-b",
		component: SingleProductComponent,
		title: "1 Month Subscription - Bullymake Box - A Dog Subscription Box For Power Chewers!",
		data: { id: 3276883 },
	},
	{
		path: "product/1-month-subscription/promotion-c",
		component: SingleProductComponent,
		title: "1 Month Subscription - Bullymake Box - A Dog Subscription Box For Power Chewers!",
		data: { id: 3276883 },
	},
	{
		path: "product/1-month-subscription/promotion-fb20",
		component: SingleProductComponent,
		title: "1 Month Subscription - Bullymake Box - A Dog Subscription Box For Power Chewers!",
		data: { id: 3276883 },
	},
	{
		path: "toys",
		redirectTo: 'toys-detail', pathMatch: 'full'
	},
	{
		path: "toys-detail",
		loadComponent: () =>
			import("./pages/toys-detail/toys-detail.component").then(
				(x) => x.ToysDetailComponent
			),
		title: "Rubber Dog Toys | Dog Rope Toy | Dog Safe Materials for Toys",
	},
	{
		path: "subscription-box",
		component: SubscriptionBoxAComponent,
		title: "BULLYMAKE Subscription Box | Durable Toys for Power Chewers",
	},
	{
		path: "subscription-box-b",
		component: SubscriptionBoxBComponent,
		title: "BULLYMAKE Subscription Box | Durable Toys for Power Chewers",
	},
	{
		path: "subscription-box-b2",
		component: SubscriptionBoxB2Component,
		title: "BULLYMAKE Subscription Box | Durable Toys for Power Chewers",
	},
	{
		path: "subscription-box-c",
		component: SubscriptionBoxCComponent,
		title: "BULLYMAKE Subscription Box | Durable Toys for Power Chewers",
	},
	{
		path: "subscription-box-20",
		component: SubscriptionBoxB20Component,
		title: "BULLYMAKE Subscription Box | Durable Toys for Power Chewers",
	},
	{
		path: "terms-and-conditions",
		loadComponent: () =>
			import(
				"./pages/terms-and-conditions/terms-and-conditions.component"
			).then((x) => x.TermsAndConditionsComponent),
		title: "Terms & Conditions | BULLYMAKE Dog Subscription Box",
	},
	{
		path: "refundpolicy",
		loadComponent: () =>
			import("./pages/refund-policy/refund-policy.component").then(
				(x) => x.RefundPolicyComponent
			),
		title: "BULLYMAKE Refund Policy | How to Request a Refund",
	},
	{
		path: "accessibility",
		loadComponent: () =>
			import(
				"./pages/accessibility-statement/accessibility-statement.component"
			).then((x) => x.AccessibilityStatementComponent),
		title: "BULLYMAKE Accessibility Statement | Our Commitment",
	},
	{
		path: "privacy-policy",
		loadComponent: () =>
			import("./pages/privacy-policy/privacy-policy.component").then(
				(x) => x.PrivacyPolicyComponent
			),
		title: "Privacy Policy | BULLYMAKE Dog Toys Box for Heavy Chewers",
	},
	{
		path: "cookie-policy",
		loadComponent: () =>
			import("./pages/cookie-policy/cookie-policy.component").then(
				(x) => x.CookiePolicyComponent
			),
		title: "Cookie Policy | BULLYMAKE Dog Toys Box for Heavy Chewers",
	},
	{
		path: "removal",
		component: RemovalComponent,
		title: "Remove Your Personal Data from BULLYMAKE's Email List",
	},
	{
		path: "stop-destructive-chewing",
		loadComponent: () =>
			import(
				"./pages/stop-destructive-chewing/stop-destructive-chewing.component"
			).then((x) => x.StopDestructiveChewingComponent),
		title: "Stop Destructive Chewing - Bullymake Box - A Dog Subscription Box For Power Chewers!",
	},
	{
		path: "ugc-policy",
		loadComponent: () =>
			import("./pages/ugc-policy/ugc-policy.component").then(
				(x) => x.UgcPolicyComponent
			),
		title: "UGC Policy | BULLYMAKE Dog Chew Toys for Aggressive Chewers",
	},
	{
		path: "safety",
		loadComponent: () =>
			import("./pages/safety/safety.component").then(
				(x) => x.SafetyComponent
			),
		title: "Safety Information | Dog Safe Materials for Chewing",
	},
	{
		path: "how-this-dog-company-changed-the-game-for-power-chewing-dogs",
		component: WhyBullymakeAComponent,
		title: "How BULLYMAKE Revolutionized Dog Toys for Power Chewers",
	},
	{
		path: "how-this-dog-company-changed-the-game-for-power-chewing-dogs-b",
		component: WhyBullymakeBComponent,
		title: "How BULLYMAKE Revolutionized Dog Toys for Power Chewers",
	},
	{
		path: "how-this-dog-company-changed-the-game-for-power-chewing-dogs-c",
		component: WhyBullymakeCComponent,
		title: "How BULLYMAKE Revolutionized Dog Toys for Power Chewers",
	},
	{
		path: "dog-gift",
		component: DogGiftComponent,
		title: "BULLYMAKE Dog Boxes | Perfect Gifts for Dogs | Dog Gifts",
	},
	{
		path: "help",
		loadComponent: () =>
			import("./pages/help/help.component").then((x) => x.HelpComponent),
		title: "BULLYMAKE Customer Service | We're Here to Help",
	},
	{
		path: "black-friday",
		component: BlackFridayComponent,
		title: "BULLYMAKE Black Friday Deals | Dog Toys Coupon",
	},
	{
		path: "unsubscribe",
		component: UnsubscribeComponent,
		title: "Unsubscribe from BULLYMAKE Emails",
	},
	{
		path: "success",
		loadComponent: () =>
			import("./pages/success/success.component").then(
				(x) => x.SuccessComponent
			),
		title: "Bullymake Box - A Dog Subscription Box For Power Chewers!",
	},
	{
		path: "beds",
		component: BedsComponent,
		title: "Bullymake Box - A Dog Subscription Box For Power Chewers!",
	},
	{
		path: "blog",
		component: BullymakeBlogComponent,
		title: "Bullymake Blog - Bullymake Box - A Dog Subscription Box For Power Chewers!",
	},
	{
		path: "blog/page/:page",
		component: BullymakeBlogComponent,
		title: "Bullymake Blog - Bullymake Box - A Dog Subscription Box For Power Chewers!",
	},
	{
		path: "blog/:slug",
		component: SinglePostComponent,
		title: "Bullymake Blog - Bullymake Box - A Dog Subscription Box For Power Chewers!",
	},
	{
		path: "signin",
		component: LoginComponent,
		title: "Signin - Bullymake Box - A Dog Subscription Box For Power Chewers!",
	},
	{
		path: "dog-boredom",
		component: DogBoredomComponent,
		title: "Dog Boredom - Bullymake Box - A Dog Subscription Box For Power Chewers!",
	},
	{
		path: "promotion",
		component: PromotionsComponent,
		title: "Bullymake Box - A Dog Subscription Box For Power Chewers!",
	},
	{
		path: "w9",
		loadChildren: () =>
			import("./modules-page/w9/w9.module").then((m) => m.W9Module),
		title: "W9 / W-8BEN Forms - Bullymake Box - A Dog Subscription Box For Power Chewers!",
	},
	{
		path: "my-account",
		canActivate: [AuthGuard],
		loadChildren: () =>
			import("./modules-page/my-account/my-account.module").then(
				(m) => m.MyAccountModule
			),
		title: "My Account - Bullymake Box - A Dog Subscription Box For Power Chewers!",
	},
	{
		path: "checkout",
		loadChildren: () =>
			import("./modules-page/checkout/checkout.module").then(
				(m) => m.CheckoutModule
			),
		title: "Checkout - Bullymake Box - A Dog Subscription Box For Power Chewers!",
	},
	{
		path: "affiliate",
		component: AffiliateComponent,
		title: "Join the Best Affiliate Marketing Program for Dog Lovers | BULLYMAKE",
	},
	{
		path: "**",
		loadComponent: () =>
			import("./pages/not-found/not-found.component").then(
				(x) => x.NotFoundComponent
			),
		title: "Page not found - Bullymake Box - A Dog Subscription Box For Power Chewers!",
	},
];
export const routing = RouterModule.forRoot(appRoutes, {
	relativeLinkResolution: "legacy",
});
