import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CartSelection, IProduct, IProductOpts } from 'bullymake-types';

// import { CartService } from './cart/cart.service';


@Injectable({
	providedIn: 'root'
})
export class ProductsService {

	// public products = Array<Object>;

	constructor(public router: Router, public route: ActivatedRoute) { }

	getProduct(id) {

		var data: IProduct = {
			slides: [],
			duration: 0,
			price: 0,
			fullPrice: '',
			period: '',
			note: '',
			total: 0,
			content_id: ''
		};

		switch (id) {
			case 3276883: {

				data.slides = [
					{ url: 'assets/img/single-product/slides/slide1/slide-1m.jpg' },
					// {url: 'assets/img/single-product/slides/slide2/slide-june-2022.jpg'},
					{ url: 'assets/img/single-product/slides/slide3.jpg' },
					{ url: 'assets/img/single-product/slides/slide4.jpg' },
					{ url: 'assets/img/single-product/slides/slide5.jpg' },
					{ url: 'assets/img/single-product/slides/slide6.jpg' },
					{ url: 'assets/img/single-product/slides/slide7.jpg' },
					{ url: 'assets/img/single-product/slides/slide8.jpg' },
					{ url: 'assets/img/single-product/slides/slide9.jpg' },
					{ url: 'assets/img/single-product/slides/slide10.jpg' }
				];
				data.duration = 1;
				data.price = 45;
				data.fullPrice = '$45 every Month';
				data.period = 'Month';
				data.note = '';
				data.content_id = '1_648';
				data.promotions = {
					a: {
						price: 45,
						fullPrice: '$45 every Month',
						coupon: 'FREETOY',
						note: '<strong class="d-block text-green sm-lead text-uppercase">Free Bonus Toy Each Month!</strong> Discount auto applied.'
					},
					b: {
						price: 27,
						fullPrice: 'Your first month - just $27',
						coupon: 'FB40',
						note: '40% off your first box! Discount auto applied.'
					},
					c: {
						price: 31,
						fullPrice: '$31 every Month',
						coupon: 'BM20',
						note: '20% Discount Auto Applied'
					},
					fb20: {
						price: 31,
						fullPrice: 'Your first month - just $31',
						coupon: 'FB20',
						note: '20% off your first box! Discount auto applied.'
					},
				}

				break;
			}
			case 3276878: {

				data.slides = [
					{ url: 'assets/img/single-product/slides/slide1/slide-3m.jpg' },
					// {url: 'assets/img/single-product/slides/slide2/slide-june-2022.jpg'},
					{ url: 'assets/img/single-product/slides/slide3.jpg' },
					{ url: 'assets/img/single-product/slides/slide4.jpg' },
					{ url: 'assets/img/single-product/slides/slide5.jpg' },
					{ url: 'assets/img/single-product/slides/slide6.jpg' },
					{ url: 'assets/img/single-product/slides/slide7.jpg' },
					{ url: 'assets/img/single-product/slides/slide8.jpg' },
					{ url: 'assets/img/single-product/slides/slide9.jpg' },
					{ url: 'assets/img/single-product/slides/slide10.jpg' }
				];
				data.duration = 3;
				data.price = 35;
				data.fullPrice = '$35 every Month';
				data.period = 'Months';
				data.note = '11% discount vs monthly subscription';
				data.content_id = '2_647';

				break;
			}
			case 3276880: {

				data.slides = [
					{ url: 'assets/img/single-product/slides/slide1/slide-6m.jpg' },
					// {url: 'assets/img/single-product/slides/slide2/slide-june-2022.jpg'},
					{ url: 'assets/img/single-product/slides/slide3.jpg' },
					{ url: 'assets/img/single-product/slides/slide4.jpg' },
					{ url: 'assets/img/single-product/slides/slide5.jpg' },
					{ url: 'assets/img/single-product/slides/slide6.jpg' },
					{ url: 'assets/img/single-product/slides/slide7.jpg' },
					{ url: 'assets/img/single-product/slides/slide8.jpg' },
					{ url: 'assets/img/single-product/slides/slide9.jpg' },
					{ url: 'assets/img/single-product/slides/slide10.jpg' }
				];
				data.duration = 6;
				data.price = 34;
				data.fullPrice = '$34 every Month';
				data.period = 'Months';
				data.note = '12% discount vs monthly subscription';
				data.content_id = '3_646';

				break;
			}
			case 3276881: {

				data.slides = [
					{ url: 'assets/img/single-product/slides/slide1/slide-12m.jpg' },
					// {url: 'assets/img/single-product/slides/slide2/slide-june-2022.jpg'},
					{ url: 'assets/img/single-product/slides/slide3.jpg' },
					{ url: 'assets/img/single-product/slides/slide4.jpg' },
					{ url: 'assets/img/single-product/slides/slide5.jpg' },
					{ url: 'assets/img/single-product/slides/slide6.jpg' },
					{ url: 'assets/img/single-product/slides/slide7.jpg' },
					{ url: 'assets/img/single-product/slides/slide8.jpg' },
					{ url: 'assets/img/single-product/slides/slide9.jpg' },
					{ url: 'assets/img/single-product/slides/slide10.jpg' }
				];
				data.duration = 12;
				data.price = 29;
				data.period = 'Months';
				data.fullPrice = '$29 every Month';
				data.note = '25% discount vs monthly subscription';
				data.content_id = '4_642';

				break;
			}
			case 59533: {

				data.slides = [
					{ url: 'assets/img/single-product/slides/slide1/slide-1m.jpg' },
					// {url: 'assets/img/single-product/slides/slide2/slide-june-2022.jpg'},
					{ url: 'assets/img/single-product/slides/slide3.jpg' },
					{ url: 'assets/img/single-product/slides/slide4.jpg' },
					{ url: 'assets/img/single-product/slides/slide5.jpg' },
					{ url: 'assets/img/single-product/slides/slide6.jpg' },
					{ url: 'assets/img/single-product/slides/slide7.jpg' },
					{ url: 'assets/img/single-product/slides/slide8.jpg' },
					{ url: 'assets/img/single-product/slides/slide9.jpg' },
					{ url: 'assets/img/single-product/slides/slide10.jpg' }
				];
				data.duration = 1;
				data.price = 31;
				data.fullPrice = '$31 every Month';
				data.period = 'Month';
				data.note = '';
				data.content_id = '5_59533';

				break;
			}
		}

		data.total = data.duration * data.price;

		return data;
	}

	getFields() {

		var fields: IProductOpts = {
			boxContent: [],
			allergies: [],
			toys: [],
			extraToy: []
		}

		fields.boxContent = [
			{ label: 'Toys & Treats', value: 'toys-and-treat', selected: true, tooltip: 'This is our standard box and most popular selection. It will come with 2-3 toys and 3 treats per box.' },
			{ label: 'Toys Only', value: 'toys-only', selected: false, tooltip: 'Our toys only option will have 4 toys and 0 treats.  Consider this option if you\'d only like our toys, or if your dog has severe food allergies.' },
		];

		fields.allergies = [
			{ label: 'No Allergies', value: 'no-allergies', selected: true },
			{ label: 'Grain Allergy', value: 'grain', selected: false },
			{ label: 'Beef / Grain Allergy', value: 'beef-grain', selected: false },
			{ label: 'Chicken / Grain Allergy', value: 'chicken-grain', selected: false }
		];

		fields.toys = [
			{ label: 'Nylon', value: 'nylon', selected: true, tooltip: 'Nylon is one of our specialty toy materials that is made here in the USA.  It is one of our most durable options, and is perfect for dogs that love to gnaw and enjoy various textures and scents all while promoting healthy chewing.' },
			{ label: 'Rubber', value: 'rubber', selected: false, tooltip: 'Rubber is also made in the USA and is highly specialized by BULLYMAKE.  It has more give than nylon and is also multi-purpose.  It can used as a treat dispensing toy or even a great fetch & chew toy.' },
			{ label: 'Rope', value: 'rope', selected: false, tooltip: 'Rope toys are very versatile and offer dogs the option to tug.  If your dog loves to tug then we recommend adding a rope toy to your box.  It isn\'t as tough as nylon or rubber when it comes to chewing, but is also a great chew toy option!' },
			{ label: 'Ballistic', value: 'ballistic', selected: false, tooltip: 'Ballistic is a unique material that we make here in the USA. It is softer than nylon and rubber and has the mouth feel of cloth.  It is great for tugging and pulling, and is an interactive toy more than a chew.  If your dog likes the mouth feel of cloth, but doesn\'t like things as rough and tough as nylon/rubber - ballistic is a great option.' },
			{ label: 'Plush', value: 'plush', selected: false, tooltip: 'Rope toys are very versatile and offer dogs the option to tug.  If your dog loves to tug then we recommend adding a rope toy to your box.  It isn\'t as tough as nylon or rubber when it comes to chewing, but is also a great chew toy option!' }, // TODO: Update Plush Tooltip
		];

		fields.extraToy = [
			{ label: 'Yes', value: 'yes', selected: true },
			{ label: 'No', value: 'no', selected: false },
		];

		return fields;
	}

	/**
	 * Get Cart Selection with defaults
	 * @param selection Partial<CartSelection>
	 * @returns CartSelection
	 */
	public getDefaultSelection(selection: Partial<CartSelection> = {}): CartSelection {

		// Default Selection
		const defaultSelection: CartSelection = {
			unit: 'lbs',
			weight: 40,
			allergies: 'no-allergies',
			box_items: 'toys-and-treat',
			first_toy: 'nylon',
			second_toy: 'rubber',
			third_toy: 'rope',
			fourth_toy: 'nylon',
			add_extra_toy: 'no',
			extra_toy: 'nylon',
			custom_box: false,
			dog_name: '',
			dog_breed: '',
			email: '',
			addon: '',
			addon_toy: 'nylon',
			addons: {
				addon_1: '',
				addon_2: '',
				addon_3: '',
				addon_4: ''
			}
		}

		// Merge selection with defaults and return `CartSelection`
		return {...defaultSelection, ...selection};
	}
}
