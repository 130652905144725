import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-dog-toy-c',
  templateUrl: './dog-toy-c.component.html',
  styleUrls: ['./dog-toy-c.component.css']
})
export class DogToyCComponent implements OnInit {

  constructor(private meta:Meta) {
	this.meta.updateTag({
		name: "description",
		content:
			"Discover the best dog toys for aggressive chewers from BULLYMAKE. Durable, safe, and built to last. Perfect for tough chewing dogs!",
	});
   }

  ngOnInit() {
  }

}
