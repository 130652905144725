import { Component, OnDestroy, OnInit, Renderer2 } from "@angular/core";
import { Meta } from "@angular/platform-browser";

@Component({
	selector: "app-affiliate",
	templateUrl: "./affiliate.component.html",
	styleUrls: ["./affiliate.component.css"],
})
export class AffiliateComponent implements OnInit,OnDestroy {

	/**
	 * Dependency Injection
	 * @param meta Meta
	 * @param renderer2 Renderer2
	 */
	constructor(private meta: Meta, private renderer2: Renderer2) {
		this.meta.updateTag({
			name: "description",
			content:
				"Join BULLYMAKE’s affiliate program and earn $40 per subscription. Promote durable toys and treats for power chewers with confidence. Apply today",
		});
	}

	/**
	 * Initialize the Component
	 */
	ngOnInit(): void {
		// Add Checkout Class to Body
		this.renderer2.addClass(document.body as HTMLBodyElement, "affiliate");
	}

	/**
	 * Destroy the Component
	 */
	ngOnDestroy(): void {
		// Remove Checkout Class to Body
		this.renderer2.removeClass(document.body as HTMLBodyElement, "affiliate");
	}
}
