import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { GoogleReview, GoogleReviewResponse } from 'bullymake-types';
import { GoogleReviewsService } from './google-reviews.service';
import { Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.css']
})
export class ReviewsComponent implements OnInit{

    public reviews:Array<GoogleReview> = [];

    private page:number = 0;

    public loadMore:boolean = true;

    public loading:boolean = false;

    constructor(
        private googleReviewsService: GoogleReviewsService,
		private meta:Meta
    ) {

		this.meta.updateTag({
			name: "description",
			content:
				"Read customer reviews of BULLYMAKE's Dog Toys and Treats Box. Discover why pet owners trust our durable toys and tasty treats for their power chewers.",
		});
	}

    ngOnInit() {

        this.getGoogleReviews();

        this.loadMoreReviews();
    }

    /**
     * Make request to DB to load reviews
     */
    getGoogleReviews() {

        this.loading = true;

        this.googleReviewsService.getReviews(false, this.page)
            .subscribe((response:HttpResponse<GoogleReviewResponse>) => {

                this.reviews = response.body.reviews;
                this.loading = false;
            });
    }

    /**
     * Binded with button to load more reviews
     */
    loadMoreReviews(){

        this.loading = true;

        this.page++;

        this.googleReviewsService.getReviews(false, this.page)
            .subscribe(response => {
                if (response.body.reviews.length === 0) {
                    this.loadMore = false;
                } else {
                    response.body.reviews.forEach(review => {
                        this.reviews.push(review);
                    });
                }

                this.loading = false;
            });
    }


}
