<footer id="footer" class="text-light text-center text-md-left">
	<div class="container">
	  <div class="row">
		<div class="col-md-10">
		  <ul id="footer-navigation" class="footer-nav block list-inline d-flex justify-content-center">
			<li class="menu-item list-inline-item"><a [routerLink]="['/my-account']">Manage Account</a></li>
			<li class="menu-item list-inline-item"><a [routerLink]="['/contact-us']">Contact Us</a></li>
			<li class="menu-item list-inline-item"><a [routerLink]="['/faq']">FAQ</a></li>
			<li class="menu-item list-inline-item"><a [routerLink]="['/terms-and-conditions']">Terms and Conditions</a></li>
			<li class="menu-item list-inline-item"><a [routerLink]="['/privacy-policy']">Privacy Policy</a></li>
		    <li class="menu-item list-inline-item"><a [routerLink]="['/cookie-policy']">Cookie Policy</a></li>
		  </ul>
		  <ul class="footer-nav block list-inline d-flex justify-content-center">
			<li class="menu-item list-inline-item"><a [routerLink]="['/ugc-policy']">UGC policy</a></li>
			<li class="menu-item list-inline-item"><a [routerLink]="['/accessibility']">Accessibility Statement</a></li>
			<li class="menu-item list-inline-item"><a [routerLink]="['/affiliate']">Affiliate</a></li>
		  </ul>

		  <p class="copyright">All Rights Reserved {{currentYear}} © Bullymake.</p>
		  <p class="removal">
			<a [routerLink]="['/removal']" class="text-white">Do Not Sell My Personal Information</a> |
			<a [routerLink]="['/refundpolicy']" class="text-white">Refund Policy</a> |
			<a href="tel:+1 972-643-8576" class="text-white">+1 972-643-8576</a>
		  </p>

		  <ul class="footer-social list-inline pb-0">
			<li class="d-inline-block"><a href="http://facebook.com/bullymake" target="_blank"><i class="icon-facebook"></i></a></li>
			<li class="d-inline-block"><a href="http://twitter.com/bullymake" target="_blank"><i class="icon-twitter"></i></a></li>
			<li class="d-inline-block"><a href="http://pinterest.com/bullymake" target="_blank"><i class="icon-pinterest"></i></a></li>
			<li class="d-inline-block"><a href="http://instagram.com/bullymake" target="_blank"><i class="icon-instagram"></i></a></li>
		  </ul>
		</div>
		<div class="col-md-2">
		  <img src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/accepted-cards-665b0bd67f7aa.webp" width="221" height="19">
		</div>
	  </div><!-- END .row -->
	</div><!-- END .container -->
  </footer>
