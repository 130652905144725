<section id="section1" class="section-item">
  <div class="container h-100">
    <h3 class="section-title text-uppercase text-orange mb-3 text-center">WHY BULLYMAKE IS CHANGING THE GAME IN DOG SUBSCRIPTION BOXES</h3>

    <div class="section-content mt-5">
      <img class="img-fluid rounded mb-4 shadow" src="https://bullymake.com/wp-content/uploads/2019/01/subscription-box-photo1.jpg" alt="">
      <!-- <img class="img-fluid rounded mb-4 mt-4 shadow" src="/assets/img/bullymake-thanksgiving-b.jpg" alt=""> -->

      <!-- <h4 class="text-uppercase text-orange text-center  mt-5">ORDER BY DECEMBER 18 FOR DELIVERY BY CHRISTMAS!</h4> -->
      <p class="lead mt-5">Karyn Owens loves her Pittie, Duke, but frankly, she was getting a little sick of him destroying all of his toys after five minutes of chewing and play time.</p>
      <p class="lead">Too many trips to the store and too many poor quality purchases online, and what happened to Karyn? She lost time, she lost money – but most importantly, she knows that Duke still isn’t getting the correct toys he deserves!</p>
      <p class="lead">BULLYMAKE has been around since 2014 with a special mission: keep power chewing dogs (and their parents) happy and satisfied. The way BULLYMAKE does this is by designing and manufacturing unique, highly durable toys every month catered specifically to your power chewer, <strong>right here in the USA</strong>, and shipped right to you.</p>
      <!-- <p class="lead">In comparison, other subscription box companies don't specifically cater or create products for these doggos that BULLYMAKE diverts all their attention to.</p> -->
    </div><!-- END .section-content -->
  </div><!-- END .container -->
</section><!-- END #section1 -->

<section id="section2" class="section-item bg-light">
  <div class="container h-100">
    <div class="section-content mt-3">
      <img class="img-fluid rounded shadow" src="https://bullymake.com/wp-content/uploads/2019/01/subscription-box-photo2.jpg" alt="">
      <h3 class="text-uppercase text-orange mt-5">THE GENIUS OF BULLYMAKE IS THAT YOU CAN HAVE A BOX DELIVERED TO YOU EVERY MONTH WITH THE EXACT TOYS YOUR DOG NEEDS.</h3>
      <p class="lead mt-5">Each box is filled with 2-3 amazing, highly durable toys curated specifically for your dog. You can even customize the materials of the toys you receive on a monthly basis. BULLYMAKE also sends 3 treats in every box that are all natural, healthy, and tie into the monthly theme. If your dog has severe food allergies, you can select a “Toys Only” option wherein the treats in the box are replaced with 2 additional toys.</p>
      <p class="lead">And if that wasn’t enough, BULLYMAKE designs and makes most of their products in the USA. All items are backed with a <strong>durability</strong> and <strong>satisfaction</strong> guarantee - nothing goes destroyed or it is replaced for free! Take advantage of this 40% off special offer to get your first box for just $27. Limited time only!</p>
    </div><!-- END .section-content -->
  </div><!-- END .container -->
</section><!-- END #section2 -->

<section id="section3" class="section-item text-center">
  <div class="container">
    <div class="section-content">
      <p class="lead text-center">Grab 40% off your first shipment!<br> Click here to get this special offer.</p>
      <a [routerLink]="['/product/1-month-subscription/promotion-b']" class="btn font-primary btn-join-now">GET OFFER NOW!</a>

    </div><!-- END .section-content -->
  </div><!-- END .container -->
</section><!-- END #section3 -->

<section id="section5" class="section-item text-center bg-light">
  <div class="container">
    <div class="section-content">
      <h3 class="mt-3 mb-3 text-orange text-uppercase text-center">Watch this video for more information about BULLYMAKE.</h3>
      <iframe class="bg-dark m-auto d-block" width="100%" height="450" src="https://www.youtube.com/embed/UvY1a81QeJ4" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
    </div><!-- END .section-content -->
  </div><!-- END .container -->
</section><!-- END #section5 -->

<section id="section6" class="section-item">
	<div class="container">
		<h2 class="section-title text-center text-uppercase">
			YOUR QUESTIONS - ANSWERED!
		</h2>
		<div class="section-content">
			<div class="row">
				<div class="col-sm-4">
					<div class="column-content">
						<accordion class="type-2" [closeOthers]="true">
							<accordion-group heading="What's in the box?">
								<p>
									Depending on your order, you will receive
									2-3 toys and 3 full bags of grain free, USA
									made treats. We also have a "toys only"
									options where you will receive 4-5 toys
									instead of the treats.
								</p>
							</accordion-group>
							<accordion-group heading="When does it ship?">
								<p>
									We ship every single order the next Business
									day. All subsequent boxes are shipped 30
									days after your first shipping date.
								</p>
							</accordion-group>
							<accordion-group
								heading="Where are your products made?"
							>
								<p>
									Our toughest toys, Nylon and Rubber, are
									made in the USA. Our interactive and soft
									toys - Rope, Ballistic, & Plush, are
									designed in the USA and manufactured abroad.
									All of our treats are made in the USA.
								</p>
							</accordion-group>
							<accordion-group
								heading="What if my dog grows during the subscription?"
							>
								<p>
									No problem! You can update your dog’s weight
									as they grow at any time in the “my account”
									area!
								</p>
							</accordion-group>
						</accordion>
					</div>
					<!-- END .column-content -->
				</div>
				<!-- END .col-sm-4 -->

				<div class="col-sm-4">
					<div class="column-content">
						<accordion class="type-2" [closeOthers]="true">
							<accordion-group
								heading="Is there a guarantee if something doesn’t work?"
							>
								<p>
									You bet! We have an industry leading
									BULLYMAKE guarantee. If your dog destroys or
									is unsatisfied with any of our items, we
									replace them with completely different items
									for free.
								</p>
							</accordion-group>
							<accordion-group
								heading="Is a subscription required?"
							>
								<p>
									A multi-month subscription is not required!
									You may choose a 1 month plan and can stop
									shipments at any time. All plans do
									auto-renew and must be cancelled to stop
									shipments.
								</p>
							</accordion-group>
							<accordion-group
								heading="What are my customization options?"
							>
								<p>
									You may select from our 5 toy types: nylon,
									rubber, rope, ballistic, and plush. Once you
									complete your purchase, you can pick your
									toy preferences and update your dog's
									profile, including allergy preferences.
								</p>
							</accordion-group>
							<accordion-group
								heading="Do you ever send Rawhides?"
							>
								<p>NEVER! Too dangerous.</p>
							</accordion-group>
						</accordion>
					</div>
					<!-- END .column-content -->
				</div>
				<!-- END .col-sm-4 -->

				<div class="col-sm-4">
					<div class="column-content">
						<accordion class="type-2" [closeOthers]="true">
							<accordion-group
								heading="What if my dog has severe allergies?"
							>
								<p>
									No problem! We have a "toys only" option
									where we replace all treats in the box with
									2 additional toys, for a total of 4 toys.
								</p>
							</accordion-group>
							<accordion-group
								heading="How does it work / how do I cancel?"
							>
								<p>
									Pick any of our plans (1, 3, 6, or 12
									months). We'll ship your first box the next
									business day. Each plan automatically renews
									- and you can cancel anytime for the monthly
									one while the 3, 6 and 12 months will be
									under commitment period.
								</p>
							</accordion-group>
							<accordion-group
								heading="Can Australia & Canada receive treats?"
							>
								<p>
									At this time, Canada and Australia cannot
									receive shipments with treats. We are
									working with both borders. Orders will
									automatically be switched to our "toys only"
									option which includes 4-5 toys per shipment!
								</p>
							</accordion-group>
							<!-- <accordion-group heading="What toy customizations can I receive?">
				  <p>We'll let you select from Nylon, Rubber, Ballistic, or Rope materials. Please click "I'll do it" at the bottom of the page to get started.</p>
				</accordion-group> -->
							<accordion-group heading="Who delivers your boxes?">
								<p>
									We ship via FedEx. You may select between
									standard and express shipping.
								</p>
							</accordion-group>
						</accordion>
						<br />
					</div>
					<!-- END .column-content -->
				</div>
				<!-- END .col-sm-4 -->
			</div>
			<!-- END .row -->
		</div>
		<!-- END .section-content -->
	</div>
	<!-- END .container -->
</section>
<!-- END #section6 -->

<section id="section4" class="section-item bg-light pb-0">
  <div class="container">
    <h2 class="section-title text-dark text-uppercase mb-3 text-center">And check out all of their awesome reviews across the web:</h2>
    <div class="section-content text-center mt-5">
      <img class="bordered shadow img-fluid" src="assets/img/greviews.jpg" alt="">
    </div><!-- END .section-content -->
  </div><!-- END .container -->
</section><!-- END #section4 -->
