<div [ngClass]="hide?'hide-toast':'show-toast'" class="toast-transition">
	<a [routerLink]="'/subscribe'" style="background-color:var(--offer-toast-bg);"
		class="rounded m-3 px-4 py-2 d-flex shadow  d-block ">
		<div class="vstack mr-3 my-auto">
			<img class="img-fluid" width="60px" height="60px"
				src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/bullymake-tag.webp"
				alt="bullymake logo">
		</div>
		<div>
			<p class="text-uppercase mb-2 text-dark" style="font-size: .8rem;">
				Limited Time Offer
			</p>
			<h3 class="text-white mb-0 " style="line-height: .6;">$19 First Box</h3>
			<small class="text-white">on select plans</small>
			<a [routerLink]="'/subscribe'" class="d-block text-dark small">Claim Offer
				<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
					class="bi bi-arrow-right-short" viewBox="0 0 16 16">
					<path fill-rule="evenodd"
						d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
				</svg>
			</a>
		</div>
	</a>
	<a href="javascript:void(0);" (click)="hide=!hide;"
		class="d-flex toggle-switch position-absolute text-dark rounded-circle " style="top:5px;right:20px;">
		<svg *ngIf="!hide;else show" xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
			class="bi bi-x" viewBox="0 0 16 16">
			<path
				d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
		</svg>

		<ng-template #show>
			<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
				class="bi bi-arrow-right-short" viewBox="0 0 16 16">
				<path fill-rule="evenodd"
					d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
			</svg>
		</ng-template>
	</a>

</div>
