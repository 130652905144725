import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AccordionModule } from 'ngx-bootstrap/accordion';

@Component({
  selector: 'app-black-friday',
  templateUrl: './black-friday.component.html',
  styleUrls: ['./black-friday.component.css'],
  standalone: true,
  imports: [
	RouterModule,
	AccordionModule
  ]
})
export class BlackFridayComponent implements OnInit {

  constructor(private meta:Meta) {

	this.meta.updateTag(
		{
			name: "description",
			content: "Don't miss BULLYMAKE's Black Friday deal! Save big on durable dog toys with exclusive coupons. Perfect for tough chewers. Shop now!"
		}
	)
   }

  ngOnInit(): void {
  }

}
