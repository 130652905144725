// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	envName: 'staging',
	production: false,
	TOKEN_KEY: 'bm2431fd5ed2b01',
	API_ROOT: 'https://staging.bullymake.com/wp-json/',
	RECAPTCHA_SITEKEY: '6LcWnsEUAAAAAFJaYlUj3Pv1FimxNM-WGvGAVm8P',
	STRIPE_KEY: 'pk_test_4NZGHa71j4uCi4ZMhK1G12wa'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
